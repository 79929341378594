import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IFilterDto, RolesType, SelectedLanguage, UnitDetailsDto } from 'api/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { getFilterOptions } from 'state/ducks/filter-search/operations';
import { getUserGroupsChildHierarchy, getUsersSuperAdminGroupsChildHierarchy } from 'state/ducks/groups/operations';
import { getDropDownOptions, getUnitDetails } from 'state/ducks/machine-details/operations';
import { GenericLevelLimits } from 'state/ducks/machine-details/types';
import { deleteMachine, getMachineCount } from 'state/ducks/machines/operations';
import { GetCustomMessages } from 'state/ducks/notification-custom-message/operations';
import { getNotificationDefinitionsForMachine } from 'state/ducks/notifications/operations';
import { Routes } from 'state/ducks/routes';
import { getServiceVideosByMachineTypeOperation } from 'state/ducks/service-video/operations';
import {
	createTeltonikaIndicator,
	deleteTeltonikaIndicator,
	getTeltonikaIndicator,
	updateTeltonikaIndicator,
} from 'state/ducks/teltonika-indicator/operations';
import {
	createUnitTracker,
	deleteUnitTracker,
	getUnitTracker,
	updateUnitTrackerId,
} from 'state/ducks/tracker/operations';
import {
	createUnitWeight,
	deleteUnitWeight,
	getUnitWeight,
	updateUnitWeight,
} from 'state/ducks/unit-weight/operations';
import { getUserSettings } from 'state/ducks/user-settings/operations';
import {
	createXtelDeviceTracker,
	deleteXtelDeviceTracker,
	getXtelUnitTracker,
	updateXtelDeviceTrackerId,
} from 'state/ducks/xtel-tracker/operations';
import history from 'state/history';
import { localized } from 'state/i18n';
import { AppState } from 'state/store';
import { IsBrandBramidan } from 'utilities/brand-utils';
import { IconBigSize } from 'utilities/constants';
import { images } from 'utilities/images';
import { getMachineFromIdDataMapOrDefault } from 'utilities/machine-util';
import {
	hasAtLeast,
	isAtLeastAdminFromState,
	isAtLeastCountryAdminFromState,
	isAtLeastSuperAdminFromState,
	isServiceTechnicianFromState,
	isServiceToolCodeFromState,
} from 'utilities/roles-util';
import { getEmailFromToken } from 'utilities/token-util';
import { CloseButtonDialog } from 'view/components/video/close-button-dialog';
import VimeoIframe from 'view/components/vimeo-iframe';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import WhiteCard from 'view/shared/components/cards/white-card';
import { getDateToString } from 'view/shared/components/date-to-string';
import LabelValueCard, {
	addTranslationLabelValueMapToList,
	getLabelValueOrDefaultToLocalizedNoDataString,
	LabelValueForCardMap,
} from 'view/shared/components/label-value-card';
import ResponsiveSpacingWrapper from 'view/shared/components/responsive-wrappers/responsive-spacing-wrapper';
import { getIcon } from 'view/shared/utils/icon-helper';
import { ConfirmDialog } from '../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import { getOnlineStatusAsIconAndTextJsx } from '../../../shared/components/online-status/online-status';
import { Spinner } from '../../spinner/spinner';
import { AddDevice, AddDeviceDto } from './add-device';
import { BaleSettings } from './cards/bale-settings/bale-settings';
import BiMap from './cards/bi-map';
import { DebtorDetails } from './cards/debtor-details';
import MachineOwnerLocation from './cards/machine-owner-location';
import { NotificationSubscriptionList } from './cards/notification-subscriptions/notification-subcription-list';
import NotificationsCard from './cards/notifications';
import TechnicalDetails from './cards/technical-details';
import { WasteHandlersEmptyings } from './cards/waste-handlers-emptyings';
import WeightCard from './cards/weight';
import {
	cardDetailsStyles,
	cardGeneralClassNames,
	cardGraphStyles,
	cardGreenData,
	cardOrangeData,
	cardRedData,
	SubtitleData,
} from './constants';
import CustomMessageDialog from './custom-message-dialog';
import './details.scss';
import { DialogServiceCodeExistingUnit } from './dialog-service-code-existing-unit';
import {
	getManualItemsFromDeviceDocuments,
	getManualItemsFromServiceTechnicianVideos,
	ManualItem,
	ManualType,
} from './documents-helper';

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps<AppRouterProps>) => {
	const serialNumber = decodeURIComponent(ownProps.match.params.url_id);
	const currMachine = getMachineFromIdDataMapOrDefault(
		state.machineDetailsReducer.machineDetails,
		serialNumber,
		UnitDetailsDto
	);
	const userGroups = state.groupsReducer.groups.find(g => g.id === getEmailFromToken());
	const currentGroup = userGroups?.data.find(g => g.id === currMachine.data.ownerGroupId);
	const userRole = currentGroup?.roleType ?? RolesType.DataOutOfRange;

	const serviceTechnicianVideos = state.serviceVideoReducer.mappedVideos.find(
		vid => vid.name === currMachine.data.unitModelName
	);
	const isLoadingServiceVideos = state.serviceVideoReducer.isLoading;

	const userSetting = state.userSettingReducer.userSettings;
	const userLanguage = userSetting.selectedLanguage;

	const weightIsActive = state.unitWeightReducer.unitWeights.find(
		uw => uw.unitId === currMachine.data.id && uw.isActive
	);

	const teltonikaIndicatorIsActive = state.teltonikaIndicatorReducer.teltonikaIndicators.find(
		ti => ti.unitId === currMachine.data.id && ti.isActive
	);

	const xtelTrackerIsActive = state.xtelTrackerReducer.xtelTrackers.find(
		xt => xt.unitId === currMachine.data.id && xt.isActive
	);

	const deviceTrackerIsActive = state.deviceTrackerReducer.unitTrackers.find(
		dt => dt.unitId === currMachine.data.id && dt.isActive
	);

	return {
		appliedFilterDto: state.filterSearchReducer.appliedFilters,
		isAdmin: isAtLeastAdminFromState(state, currentGroup?.id),
		isCountryAdmin: isAtLeastCountryAdminFromState(state, currentGroup?.id),
		isSuperAdmin: isAtLeastSuperAdminFromState(state),
		customMessages: state.notificationCustomMessageReducer.customMessages,
		deviceId: currMachine.data.id,
		signalStrength: currMachine.data?.signalStrength,
		lastOnline: currMachine.data?.lastestChange,
		onlineStatus: currMachine.data.isOnline,
		currMachine: currMachine,
		machineType: currMachine.data?.unitModelName,
		latitude: currMachine.data?.latitude ? currMachine.data?.latitude : currMachine.data?.homeLatitude,
		longitude: currMachine.data?.longitude ? currMachine.data?.longitude : currMachine.data?.homeLongitude,
		deviceTrackerId: state.deviceTrackerReducer.unitTrackers.find(dt => dt.unitId === currMachine.data.id)
			?.trackerId,
		xtelTrackerId: state.xtelTrackerReducer.xtelTrackers.find(dt => dt.unitId === currMachine.data.id)?.trackerId,
		unitWeightId: state.unitWeightReducer.unitWeights.find(dt => dt.unitId === currMachine.data.id)
			?.weighingSerialNumber,
		teltonikaIndicatorId: state.teltonikaIndicatorReducer.teltonikaIndicators.find(
			dt => dt.unitId === currMachine.data.id
		)?.teltonikaImei,
		deviceDocuments: currMachine.data.unitDocument,
		unitController: currMachine.data.controllerName,
		userRole,
		machineDetails: state.machineDetailsReducer.machineDetails,
		serialNumber,
		isServiceTechnician: isServiceTechnicianFromState(state, currentGroup?.id),
		isServiceToolCode: isServiceToolCodeFromState(state, currentGroup?.id),
		serviceTechnicianVideos,
		isLoadingServiceVideos,
		userLanguage,
		weightIsActive,
		xtelTrackerIsActive,
		deviceTrackerIsActive,
		teltonikaIndicatorIsActive,
	};
};

export const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	getUnitDetails: async (id: string, userLanguage: SelectedLanguage) =>
		(await getUnitDetails(id, userLanguage))(dispatch),
	getUserSettings: async () => (await getUserSettings())(dispatch),
	getDropDownOptions: async () => (await getDropDownOptions())(dispatch),
	getUserGroupsChildHierarchy: async () => (await getUserGroupsChildHierarchy())(dispatch),
	deleteMachine: async (deviceId: number) => await deleteMachine(deviceId)(dispatch),
	getUsersSuperAdminGroupsChildHierarchy: async () => (await getUsersSuperAdminGroupsChildHierarchy())(dispatch),
	getFilterOptions: async () => (await getFilterOptions())(dispatch),
	getMachineCount: async (filter?: IFilterDto) => (await getMachineCount(filter))(dispatch),
	getNotificationCustomMessages: (serialNumber: string) => GetCustomMessages(serialNumber)(dispatch),
	getNotificationDefinitionsForMachine: async (id: string) =>
		(await getNotificationDefinitionsForMachine(id))(dispatch),
	updateDeviceTrackerId: async (deviceId: number, trackerId: string) =>
		await updateUnitTrackerId(deviceId, trackerId)(dispatch),
	getDeviceTracker: async (serialNumber: string) => await getUnitTracker(serialNumber)(dispatch),
	createDeviceTracker: async (deviceId: number, trackerId: string) =>
		await createUnitTracker(deviceId, trackerId)(dispatch),
	deleteDeviceTracker: async (deviceId: number) => await deleteUnitTracker(deviceId)(dispatch),
	getServiceTechnicianVideosByMachineType: async (machineType: string) =>
		(await getServiceVideosByMachineTypeOperation(machineType))(dispatch),
	updateXtelDeviceTrackerId: async (deviceId: number, trackerId: string, message: string) =>
		await updateXtelDeviceTrackerId(deviceId, trackerId, message)(dispatch),
	getXtelUnitTracker: async (unitId: string) => await getXtelUnitTracker(unitId)(dispatch),
	createXtelDeviceTracker: async (deviceId: number, trackerId: string, message: string) =>
		await createXtelDeviceTracker(deviceId, trackerId, message)(dispatch),
	deleteXtelDeviceTracker: async (deviceId: number) => await deleteXtelDeviceTracker(deviceId)(dispatch),
	updateUnitWeight: async (unitId: number, weighingSerialNumber: string) =>
		await updateUnitWeight(unitId, weighingSerialNumber)(dispatch),
	getUnitWeight: async (unitId: string) => await getUnitWeight(unitId)(dispatch),
	createUnitWeight: async (unitId: number, weighingSerialNumber: string) =>
		await createUnitWeight(unitId, weighingSerialNumber)(dispatch),
	deleteUnitWeight: async (unitId: number) => await deleteUnitWeight(unitId)(dispatch),
	updateTeltonikaIndicator: async (teltonikaImei: string, unitId: number) =>
		await updateTeltonikaIndicator(teltonikaImei, unitId)(dispatch),
	getTeltonikaIndicator: async (serialNumber: string) => await getTeltonikaIndicator(serialNumber)(dispatch),
	deleteTeltonikaIndicator: async (unitId: number) => await deleteTeltonikaIndicator(unitId)(dispatch),
	createTeltonikaIndicator: async (teltonikaImei: string, unitId: number) =>
		await createTeltonikaIndicator(teltonikaImei, unitId)(dispatch),
});

const targetAspectRatio = 480 / 712; // Dimensions of map on website - how it is shown to the user.
const targetMapHeight = 0.75 * 712; // Because the Gmaps API does not return images larger than 1280 in either dimension, the height is defined as 0.75*712px
const targetMapWidth = targetAspectRatio * targetMapHeight;

export function getSubtitleDataFromLevel(fillLevel: number): SubtitleData {
	if (fillLevel <= GenericLevelLimits.Low) {
		return cardGreenData;
	}
	if (fillLevel <= GenericLevelLimits.Medium) {
		return cardOrangeData;
	}

	// default
	return cardRedData;
}

interface AppRouterProps {
	url_id: string;
}

type State = {
	deleteDialogVisible: boolean;
	addTrackerDialogVisible: boolean;
	addXtelTrackerDialogVisible: boolean;
	addTeltonikaIndicatorDialogVisible: boolean;
	generateServiceCodeDialogVisible: boolean;
	weightDialogVisible: boolean;
	isTrackerDeviceLoading: boolean;
	isXtelTrackerDeviceLoading: boolean;
	isWeightDeviceLoading: boolean;
	isTeltonikaIndicatorLoading: boolean;
	messageDialogVisible: boolean;
	currentOpenVideoDialog: string;
	manualItems: ManualItem[];
	addEditHeader: string;
	deviceTrackerId: string;
	xtelDeviceTrackerId: string;
	unitWeightId: string;
	teltonikaIndicatorId: string;
	xtelTrackerCustomMessage: string;
	unitController: string;
};

type Props = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps> &
	RouteComponentProps<AppRouterProps>;

class MachineDetails extends React.PureComponent<Props, State> {
	overlayPanel?: OverlayPanel;
	private containerClassName = `${cardGeneralClassNames.classNameContainer} ${cardDetailsStyles.classNameContainer} card-container-no-padding`;
	constructor(props: Props) {
		super(props);

		// Queue fetch data operation as soon as possible
		this.props.getUnitDetails(props.serialNumber, this.props.userLanguage);
		this.props.getDropDownOptions();
		this.props.getUserGroupsChildHierarchy();
		this.props.getUsersSuperAdminGroupsChildHierarchy();
		this.props.getUserSettings();
		this.props.getNotificationDefinitionsForMachine(props.serialNumber);
		this.props.getDeviceTracker(props.serialNumber);
		this.props.getXtelUnitTracker(props.serialNumber);
		this.props.getUnitWeight(props.serialNumber);
		this.props.getTeltonikaIndicator(props.serialNumber);

		this.state = {
			deleteDialogVisible: false,
			addTrackerDialogVisible: false,
			addXtelTrackerDialogVisible: false,
			addTeltonikaIndicatorDialogVisible: false,
			generateServiceCodeDialogVisible: false,
			weightDialogVisible: false,
			isTrackerDeviceLoading: false,
			isXtelTrackerDeviceLoading: false,
			isWeightDeviceLoading: false,
			isTeltonikaIndicatorLoading: false,
			messageDialogVisible: false,
			currentOpenVideoDialog: '',
			manualItems: [],
			addEditHeader: '',
			deviceTrackerId: '',
			xtelDeviceTrackerId: '',
			teltonikaIndicatorId: '',
			xtelTrackerCustomMessage: '',
			unitWeightId: '',
			unitController: '',
		};
	}

	componentDidMount() {
		if (this.props.isAdmin === true) {
			this.props.getNotificationCustomMessages(this.props.serialNumber);
		}
		this.checkGetServiceVideos();
	}

	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.deviceDocuments !== this.props.deviceDocuments ||
			prevProps.serviceTechnicianVideos !== this.props.serviceTechnicianVideos
		) {
			this.setState({
				manualItems: getManualItemsFromDeviceDocuments(this.props.deviceDocuments).concat(
					getManualItemsFromServiceTechnicianVideos(this.props.serviceTechnicianVideos?.serviceVideos ?? [])
				),
			});
		}
		if (prevProps.isAdmin !== true && this.props.isAdmin === true) {
			this.props.getNotificationCustomMessages(this.props.serialNumber);
		}
		this.checkGetServiceVideos();
	}

	checkGetServiceVideos = () => {
		if (
			this.props.isServiceTechnician &&
			!this.props.isLoadingServiceVideos &&
			this.props.machineType &&
			!this.props.serviceTechnicianVideos
		) {
			this.props.getServiceTechnicianVideosByMachineType(this.props.machineType);
		}
	};

	public render() {
		const content =
			this.props.onlineStatus !== undefined ? this.getDetailsContent(this.props.serialNumber) : <Spinner />;
		const videoDialogs = this.getVideoDialogs();
		return (
			<div>
				{content}
				{videoDialogs}
				<ConfirmDialog
					header={localized('DeleteMachine')}
					visible={this.state.deleteDialogVisible}
					onHide={this.handleDeleteCancel}
					confirmColor="org-red"
					confirmMessage={localized('DeleteMachine')}
					dialogMessage={localized('DeleteConfirmMessage')}
					onConfirm={this.handleDeleteConfirmed}
					onCancel={this.handleDeleteCancel}
				/>
				<AddDevice
					header={this.state.addEditHeader}
					visible={this.state.addTrackerDialogVisible}
					isEdit={false}
					onHide={this.handleAddTrackerCancel}
					onConfirm={this.handleAddTrackerConfirmed}
					onDelete={this.handleTrackerDelete}
					currentId={this.props.deviceTrackerId}
					deviceType="t-tracker"
				/>
				<AddDevice
					header={this.state.addEditHeader}
					visible={this.state.addXtelTrackerDialogVisible}
					onHide={this.handleAddXtelTrackerCancel}
					onConfirm={this.handleAddXtelTrackerConfirmed}
					onDelete={this.handleXtelTrackerDelete}
					currentId={this.props.xtelTrackerId}
					currentMessage={this.props.currMachine.data.customXtelMessage}
					deviceType="x-tracker"
				/>
				<AddDevice
					header={this.state.addEditHeader}
					visible={this.state.weightDialogVisible}
					onHide={this.handleWeightExit}
					onConfirm={this.handleWeightUpdated}
					onDelete={this.handleWeightDelete}
					currentId={this.props.unitWeightId}
					deviceType={'weight'}
				/>
				<AddDevice
					header={this.state.addEditHeader}
					visible={this.state.addTeltonikaIndicatorDialogVisible}
					onHide={this.handleTeltonikaIndicatorExit}
					onConfirm={this.handleTeltonikaIndicatorUpdated}
					onDelete={this.handleTeltonikaIndicatorDelete}
					currentId={this.props.teltonikaIndicatorId}
					deviceType={'teltonika-indicator'}
				/>
				{this.props.isServiceToolCode && (
					<DialogServiceCodeExistingUnit
						visible={this.state.generateServiceCodeDialogVisible}
						onHide={this.handleServiceToolCodeClose}
						machineId={this.props.serialNumber}
					/>
				)}
				<CustomMessageDialog
					visible={this.state.messageDialogVisible}
					onHide={this.handleCustomMessageClose}
					header={localized('CustomMessages')}
					serialNumber={this.props.serialNumber}
				/>
			</div>
		);
	}

	private handleServiceToolCodePressed = () => {
		this.setState({ generateServiceCodeDialogVisible: true });
	};

	private handleServiceToolCodeClose = () => this.setState({ generateServiceCodeDialogVisible: false });

	private handleDeletePressed = () => this.setState({ deleteDialogVisible: true });

	private handleDeleteCancel = () => this.setState({ deleteDialogVisible: false });

	private handleDeleteConfirmed = async () => {
		await this.props.deleteMachine(this.props.deviceId);
		await this.props.getMachineCount(this.props.appliedFilterDto);
		history.push(Routes.Machines);
	};

	private handleAddXtelTrackerPressed = () =>
		this.setState({ addXtelTrackerDialogVisible: true, addEditHeader: localized('XtelTracker') });

	private handleAddTrackerPressed = () =>
		this.setState({ addTrackerDialogVisible: true, addEditHeader: localized('Tracker') });

	private handleAddTrackerCancel = () => this.setState({ addTrackerDialogVisible: false });
	private handleAddXtelTrackerCancel = () => this.setState({ addXtelTrackerDialogVisible: false });

	private handleAddTrackerConfirmed = async (dto: AddDeviceDto) => {
		this.setState({ addTrackerDialogVisible: false, isTrackerDeviceLoading: true, deviceTrackerId: dto.id });

		if (this.props.deviceTrackerId === undefined) {
			await this.props.createDeviceTracker(this.props.deviceId, dto.id);
		} else {
			await this.props.updateDeviceTrackerId(this.props.deviceId, dto.id);
		}

		this.setState({ ...this.state, isTrackerDeviceLoading: false });
	};

	private handleAddXtelTrackerConfirmed = async (dto: AddDeviceDto) => {
		this.setState({
			addXtelTrackerDialogVisible: false,
			isXtelTrackerDeviceLoading: true,
			xtelDeviceTrackerId: dto.id,
			xtelTrackerCustomMessage: dto.message,
		});

		if (this.props.xtelTrackerId === undefined) {
			await this.props.createXtelDeviceTracker(this.props.deviceId, dto.id, dto.message);
		} else {
			await this.props.updateXtelDeviceTrackerId(this.props.deviceId, dto.id, dto.message);
		}

		this.setState({ ...this.state, isXtelTrackerDeviceLoading: false });
	};

	private handleTrackerDelete = async () => {
		this.setState({ addTrackerDialogVisible: false, isTrackerDeviceLoading: true });
		await this.props.deleteDeviceTracker(this.props.deviceId);

		this.setState({
			...this.state,
			isTrackerDeviceLoading: false,
			deviceTrackerId: '',
		});
	};

	private handleXtelTrackerDelete = async () => {
		this.setState({ addXtelTrackerDialogVisible: false, isXtelTrackerDeviceLoading: true });
		await this.props.deleteXtelDeviceTracker(this.props.deviceId);

		this.setState({
			...this.state,
			isXtelTrackerDeviceLoading: false,
			xtelDeviceTrackerId: '',
		});
	};

	private handleCustomMessagePressed = () => this.setState({ messageDialogVisible: true });

	private handleCustomMessageClose = () => this.setState({ messageDialogVisible: false });

	private handleAddNewDeviceToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this.overlayPanel?.toggle(e);
	};

	private handleWeightPressed = () =>
		this.setState({ weightDialogVisible: true, addEditHeader: localized('WeightUnit') });

	private handleWeightUpdated = async (dto: AddDeviceDto) => {
		this.setState({ weightDialogVisible: false, isWeightDeviceLoading: true, unitWeightId: dto.id });

		if (this.props.unitWeightId === undefined) {
			await this.props.createUnitWeight(this.props.deviceId, dto.id);
		} else {
			await this.props.updateUnitWeight(this.props.deviceId, dto.id);
		}

		await this.props.getUnitDetails(this.props.serialNumber, this.props.userLanguage);

		this.setState({ ...this.state, isWeightDeviceLoading: false });
	};

	private handleWeightExit = () => this.setState({ weightDialogVisible: false });

	private handleWeightDelete = async () => {
		this.setState({ weightDialogVisible: false, isWeightDeviceLoading: true });
		await this.props.deleteUnitWeight(this.props.deviceId);

		this.setState({ ...this.state, isWeightDeviceLoading: false, unitWeightId: '' });
	};

	private handleTeltonikaIndicatorUpdated = async (dto: AddDeviceDto) => {
		this.setState({
			addTeltonikaIndicatorDialogVisible: false,
			isTeltonikaIndicatorLoading: true,
			teltonikaIndicatorId: dto.id,
		});

		if (this.props.teltonikaIndicatorId === undefined) {
			await this.props.createTeltonikaIndicator(dto.id, this.props.deviceId);
		} else {
			await this.props.updateTeltonikaIndicator(dto.id, this.props.deviceId);
		}

		await this.props.getUnitDetails(this.props.serialNumber, this.props.userLanguage);

		this.setState({ ...this.state, isTeltonikaIndicatorLoading: false });
	};

	private handleTeltonikaIndicatorPressed = () =>
		this.setState({ addTeltonikaIndicatorDialogVisible: true, addEditHeader: localized('TeltonikaIndicator') });

	private handleTeltonikaIndicatorExit = () => this.setState({ addTeltonikaIndicatorDialogVisible: false });

	private handleTeltonikaIndicatorDelete = async () => {
		this.setState({ addTeltonikaIndicatorDialogVisible: false, isTeltonikaIndicatorLoading: true });
		await this.props.deleteTeltonikaIndicator(this.props.deviceId);

		this.setState({ ...this.state, isTeltonikaIndicatorLoading: false, teltonikaIndicatorId: '' });
	};

	private handleVideoDialogCancel = () => this.setState({ currentOpenVideoDialog: '' });

	private handleVideoBtnPressed = (videoName: string) => this.setState({ currentOpenVideoDialog: videoName });

	private getDetailsContent = (machineId: string) => {
		let statusIcon = getOnlineStatusAsIconAndTextJsx(
			this.props.onlineStatus,
			IconBigSize,
			this.props.signalStrength,
			this.props.lastOnline,
			this.props.currMachine.data.hasModem
		);

		const cardAndBalerRow = (
			<div className="flex-direction-row margin-top-40 flex-space-between-row">
				<div className="flex-direction-column flex-fill-remaining-space">
					<div className="flex-direction-row flex-center-column margin-bottom-40">
						<h1 className="text-bold margin-bottom-0">{localized('MachineDetails')}</h1>
						<div className="margin-left-40 margin-right-40px">{statusIcon}</div>
						<NavLink
							className="margin-left-5px margin-right-5px"
							to={`${Routes.UnitHistory}/${encodeURIComponent(this.props.match.params.url_id)}`}
						>
							<BiButton colorTheme="org-dark-blue-with-grey-text">{localized('UnitHistory')}</BiButton>
						</NavLink>
						{this.props.currMachine.data.ownerGroupId && this.props.isCountryAdmin && (
							<div className="margin-left-5px margin-right-5px">
								<BiButton colorTheme="org-red" onClick={this.handleDeletePressed}>
									{localized('DeleteMachine')}
								</BiButton>
							</div>
						)}
						{this.props.currMachine.data.ownerGroupId && this.props.isServiceToolCode && (
							<div className="margin-left-5px margin-right-5px">
								<BiButton colorTheme="org-primary" onClick={this.handleServiceToolCodePressed}>
									{localized('GenerateServiceToolCode')}
								</BiButton>
							</div>
						)}
					</div>
					{/* To make the notification card fill out the row, not the column, we nest it */}
					<div className="flex-container margin-bottom-40 flex-start-column">
						<NotificationsCard machineDetailsId={machineId} />
						{this.getDevicesContent()}
					</div>
				</div>
			</div>
		);

		const { currMachine } = this.props;
		const { id } = currMachine.data;

		const content =
			machineId && currMachine ? (
				<ResponsiveSpacingWrapper>
					{cardAndBalerRow}

					<div className="flex-direction-row flex-wrap-responsive">
						<TechnicalDetails
							isEditable={this.props.isCountryAdmin}
							machineDetailsId={machineId}
							handleVideoBtnPressed={this.handleVideoBtnPressed}
							manualItems={this.state.manualItems}
						/>
						<MachineOwnerLocation
							isAdmin={this.props.isAdmin}
							isCountryAdmin={this.props.isCountryAdmin}
							isServiceTechnician={this.props.isServiceTechnician}
							machineDetailsId={machineId}
							id={id}
						/>
						{this.getLocationCard()}
						<DebtorDetails
							debtor={currMachine.data.extendedDistributor}
							groupsId={currMachine.data.ownerGroupId}
						/>
						{this.props.currMachine.data.isBaler && <BaleSettings currMachine={this.props.currMachine} />}

						<NotificationSubscriptionList machineDetailsId={currMachine.data.id} />
						{this.props.weightIsActive && <WeightCard machineDetailsId={machineId} />}
						{hasAtLeast(this.props.userRole, currMachine.data.optimizationProjectEnabledFor) && (
							<WasteHandlersEmptyings machineDetailsId={machineId} />
						)}
					</div>
				</ResponsiveSpacingWrapper>
			) : null;

		return <BiTitle title={localized('MachineDetails')}>{content}</BiTitle>;
	};

	private getLocationCard() {
		const { currMachine } = this.props;
		const { gpsLocation, gpsHomeLocation, id } = currMachine.data;
		const locationPairValues = this.getLocationPairValues();

		if (!currMachine || !((gpsLocation && gpsLocation.length) || (gpsHomeLocation && gpsHomeLocation.length))) {
			return null;
		}

		let labelValues;
		if (locationPairValues.labels.length) {
			labelValues = (
				<div className="label-values-card-container">
					<LabelValueCard labels={locationPairValues.labels} values={locationPairValues.valuesForLabels} />
				</div>
			);
		}

		return (
			<div className="flex-container-forcing">
				<WhiteCard
					title={gpsLocation && gpsLocation.length ? localized('gpsLocation') : localized('HomeLocation')}
					classNameTitle={'padding-32px'}
					classNameContainer={this.containerClassName}
				>
					{labelValues}
					<BiMap
						height={`${targetMapHeight}`}
						width={`${targetMapWidth}`}
						gpsLocation={{
							latitude: this.props.latitude,
							longitude: this.props.longitude,
						}}
						machineDetailsId={id}
						classNameContainer={`${cardGraphStyles.classNameContainer} card-padding-0 card-width-big-square margin-top-15px card-height-auto display-inherit border-shadow-dark-all-corners`}
						fillLevel={currMachine.data.fillLevel}
						hasModem={currMachine.data.hasModem}
						isOnline={currMachine.data.isOnline}
					/>
				</WhiteCard>
			</div>
		);
	}

	private getLocationPairValues(): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		if (this.props.currMachine?.data?.lastTrackerUpdate) {
			addTranslationLabelValueMapToList(
				'updatedDateFriendly',
				getLabelValueOrDefaultToLocalizedNoDataString(
					getDateToString(this.props.currMachine.data.lastTrackerUpdate)
				),
				retMap,
				false
			);
		}

		return retMap;
	}

	private getDevicesContent = () => {
		const trackerButton =
			this.props.isCountryAdmin &&
			this.props.deviceTrackerIsActive &&
			this.props.deviceTrackerId &&
			this.buildTrackerButton(
				this.handleAddTrackerPressed,
				this.state.isTrackerDeviceLoading,
				localized('Tracker')
			);
		const xtelTrackerButton =
			this.props.isCountryAdmin &&
			this.props.xtelTrackerIsActive &&
			this.props.xtelTrackerId &&
			IsBrandBramidan &&
			this.buildTrackerButton(
				this.handleAddXtelTrackerPressed,
				this.state.isXtelTrackerDeviceLoading,
				localized('XtelTracker')
			);
		const addTrackerButton =
			!this.props.deviceTrackerId &&
			this.buildAddTrackerButton(this.handleAddTrackerPressed, localized('Tracker'));
		const addXtelTrackerButton =
			!this.props.xtelTrackerId &&
			IsBrandBramidan &&
			this.buildAddTrackerButton(this.handleAddXtelTrackerPressed, localized('XtelTracker'));
		const areBramidanOnlyDevicesSet = this.props.unitWeightId && this.props.xtelTrackerId;
		const hidePrestoDevices = !IsBrandBramidan || areBramidanOnlyDevicesSet;

		return (
			<>
				{trackerButton}
				{xtelTrackerButton}
				{this.props.isCountryAdmin && this.props.weightIsActive && this.props.unitWeightId && IsBrandBramidan && (
					<BiButton
						onClick={this.handleWeightPressed}
						disabled={this.state.isWeightDeviceLoading}
						colorTheme="org-white"
						containerClassName="details-device-card margin-right-14px"
					>
						<div className="flex-direction-column flex-center-column details-device-card-fixed">
							{this.state.isWeightDeviceLoading ? (
								<Spinner
									containerClassName="flex-center-column"
									shouldOverlay={false}
									spinnerSize="spinner-container-half-size"
									useMarginsForCorrectingCentering={false}
								/>
							) : (
								<>
									<img src={images.weight} alt={localized('Weight')} />
									<span className="text-bold">{localized('Weight')}</span>
								</>
							)}
						</div>
					</BiButton>
				)}
				{this.props.isCountryAdmin &&
					this.props.teltonikaIndicatorIsActive &&
					this.props.teltonikaIndicatorId &&
					IsBrandBramidan && (
						<BiButton
							onClick={this.handleTeltonikaIndicatorPressed}
							disabled={this.state.isTeltonikaIndicatorLoading}
							colorTheme="org-white"
							containerClassName="details-device-card margin-right-14px"
						>
							<div className="flex-direction-column flex-center-column details-device-card">
								{this.state.isTeltonikaIndicatorLoading ? (
									<Spinner
										containerClassName="flex-center-column"
										shouldOverlay={false}
										spinnerSize="spinner-container-half-size"
										useMarginsForCorrectingCentering={false}
									/>
								) : (
									<>
										<img src={images.cog_wrench} alt={localized('TeltonikaIndicator')} />
										<span className="text-bold">{localized('TeltonikaIndicator')}</span>
									</>
								)}
							</div>
						</BiButton>
					)}
				{this.props.isCountryAdmin && !(this.props.deviceTrackerId && hidePrestoDevices) && (
					<div>
						<div onClick={this.handleAddNewDeviceToggle}>
							<BiButton colorTheme="org-white" containerClassName="details-device-card margin-right-14px">
								<div className="flex-direction-column flex-center-column btn-content">
									{getIcon('plus', 43)}
									<span className="text-bold">{localized('AddNewDevice')}</span>
								</div>
							</BiButton>
						</div>
						<OverlayPanel ref={el => (this.overlayPanel = el!)}>
							<div className="p-grid button-border">
								{addTrackerButton}
								{addXtelTrackerButton}

								<br />
								{!this.props.unitWeightId && IsBrandBramidan && (
									<div className="p-col-6 details-device-card">
										<BiButton
											onClick={this.handleWeightPressed}
											colorTheme="org-white"
											containerClassName="details-device-card"
											className="bi-button-big-with-soft-rounded-corners bi-button-big-border-spacing bi-button-org-white undefined"
										>
											<div className="flex-center-column">
												<div className="flex-direction-column flex-center-column details-device-card">
													<img src={images.weight} alt={localized('Weight')} />
													<span className="text-bold">{localized('Weight')}</span>
												</div>
											</div>
										</BiButton>
									</div>
								)}
								{!this.props.teltonikaIndicatorId && !this.props.unitController && IsBrandBramidan && (
									<div className="p-col-6 details-device-card">
										<BiButton
											onClick={this.handleTeltonikaIndicatorPressed}
											colorTheme="org-white"
											containerClassName="details-device-card"
											className="bi-button-big-with-soft-rounded-corners bi-button-big-border-spacing bi-button-org-white undefined"
										>
											<div className="flex-center-column">
												<div className="flex-direction-column flex-center-column details-device-card">
													<img
														src={images.cog_wrench}
														alt={localized('TeltonikaIndicator')}
													/>
													<span className="text-bold">{localized('TeltonikaIndicator')}</span>
												</div>
											</div>
										</BiButton>
									</div>
								)}
							</div>
						</OverlayPanel>
					</div>
				)}

				{this.props.isAdmin && this.props.customMessages && this.props.customMessages.length > 0 && (
					<BiButton
						onClick={this.handleCustomMessagePressed}
						colorTheme="org-white"
						containerClassName="details-device-card margin-right-14px"
					>
						<div className="flex-direction-column flex-center-column btn-content">
							<img src={images.message} alt={localized('CustomMessages')} />
							<span className="text-bold">{localized('CustomMessages')}</span>
						</div>
					</BiButton>
				)}
			</>
		);
	};

	private getVideoDialogs = () => {
		return this.state.manualItems
			.filter(manual => manual.type === ManualType.video)
			.map(manual => (
				<CloseButtonDialog
					key={manual.name}
					// manualItem={manual}
					header={manual.name}
					visible={this.state.currentOpenVideoDialog === manual.name}
					onHide={this.handleVideoDialogCancel}
					dismissableMask={true}
				>
					<VimeoIframe videoId={manual.url} />
				</CloseButtonDialog>
			));
	};

	private buildTrackerButton = (onPress: () => void, disabled: boolean, displayText: string) => {
		return (
			<BiButton
				onClick={onPress}
				disabled={disabled}
				colorTheme="org-white"
				containerClassName="details-device-card margin-right-14px"
			>
				<div className="flex-direction-column flex-center-column details-device-card-fixed">
					{disabled ? (
						<Spinner
							containerClassName="flex-center-column"
							shouldOverlay={false}
							spinnerSize="spinner-container-half-size"
							useMarginsForCorrectingCentering={false}
						/>
					) : (
						<>
							<img src={images.tracker} alt={displayText} />
							<span className="text-bold">{displayText}</span>
						</>
					)}
				</div>
			</BiButton>
		);
	};

	private buildAddTrackerButton(onPress: () => void, displayText: string): React.ReactNode {
		return (
			<div className="p-col-6 details-device-card">
				<BiButton
					onClick={onPress}
					colorTheme="org-white"
					containerClassName="details-device-card"
					className="bi-button-big-with-soft-rounded-corners bi-button-big-border-spacing bi-button-org-white undefined"
				>
					<div className="flex-center-column">
						<div className="flex-direction-column flex-center-column details-device-card-fixed">
							<img src={images.tracker} alt={displayText} />
							<span className="text-bold">{displayText}</span>
						</div>
					</div>
				</BiButton>
			</div>
		);
	}
}
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, MachineDetails, 'Machine details'));
