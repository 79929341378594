import { Column, GridColumnExtension } from '@devexpress/dx-react-grid';
import {
	CreateReportTileSettingsDto,
	DashboardClient,
	DataTypes,
	DateTypes,
	FilterDto,
	ICreateReportTileSettingsDto,
	IFilterDto,
	IGraphReportDto,
	IReportSingleUnitDto,
	ITableReportDto,
	UnitReportDto,
} from 'api/api';
import { store } from 'index';
import { Dispatch } from 'react';
import { notificationService } from 'services/notification-service';
import { Action } from 'typescript-fsa';
import { ReportTypes } from './../../../view/components/table/reports/reports';

import { AsyncOperationBuilder } from 'utilities/api-helper';
import { setCorrectTimeStamp } from 'view/shared/components/date-to-string';
import { dashboardClient, unitReportsClient } from '../api-clients';
import {
	ClearReportsAction,
	ClearReportTilePreviewAction,
	GetGraphReportAction,
	GetReportDataAction,
	GetReportTilePreviewAction,
	GetTableReportAction,
	SetDataTypeAction,
	SetDateTypeAction,
	SetEndDateAction,
	SetReportsLoadingStateAction,
	SetReportTileDateTypeAction,
	SetReportTypeAction,
	SetStartDateAction,
} from './actions';
import {
	convertMonthToJS,
	getDateIncrementerImmutable,
	getHeaderRowForReportTable,
	getStartOfDateType,
	processReportDataToGraph,
	processReportDataToTable,
} from './reports-helpers';
import { ReportsState } from './types';

export function clearReports() {
	return (dispatch: Dispatch<Action<void>>) => {
		dispatch(ClearReportsAction());
	};
}

export function setStartDate(startDate: Date) {
	return (dispatch: Dispatch<Action<Date>>) => {
		dispatch(SetStartDateAction(startDate));
	};
}

export function setEndDate(endDate: Date) {
	return (dispatch: Dispatch<Action<Date>>) => {
		dispatch(SetEndDateAction(endDate));
	};
}

export function setReportType(reportType: ReportTypes) {
	return (dispatch: Dispatch<Action<ReportTypes>>) => {
		dispatch(SetReportTypeAction(reportType));
	};
}

export function setDateType(dateType: DateTypes) {
	return (dispatch: Dispatch<Action<DateTypes>>) => {
		dispatch(SetDateTypeAction(dateType));
	};
}

export async function getReportByUnitIds(
	ids: number[],
	dataType: DataTypes,
	dateType: DateTypes,
	startDate: Date,
	endDate: Date
) {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			let callCounter = (store.getState().reportReducer as ReportsState).callCounter;
			let params = { callCounter: ++callCounter, dataTypes: dataType };
			dispatch(GetReportDataAction.started(params));

			const reportData = await unitReportsClient.getReportByUnitIds(
				ids,
				setCorrectTimeStamp(startDate, false, false),
				setCorrectTimeStamp(endDate, false, true),
				dateType,
				dataType
			);

			if (reportData.reportData && dateType === DateTypes.Month) {
				convertMonthToJS(reportData.reportData);
			}

			dispatch(GetReportDataAction.done({ params: params, result: reportData.reportData || [] }));

			if (params.callCounter === (store.getState().reportReducer as ReportsState).callCounter) {
				// Let's process it into graph- and table data
				getGraphAndTableReport(reportData, dataType, dateType, startDate, endDate)(dispatch);
			}
		} catch (error) {
			notificationService.showErrorMessageWithError((error as any).toString(), undefined, error);
		}
	};
}

export function setDatatype(Dt: DataTypes | undefined) {
	return async (dispatch: Dispatch<Action<any>>) => {
		dispatch(SetDataTypeAction(Dt));
	};
}

export async function getReportByFilter(
	filter: IFilterDto,
	dataType: DataTypes,
	dateType: DateTypes,
	startDate: Date,
	endDate: Date
) {
	return async (dispatch: Dispatch<Action<{}>>) => {
		try {
			if (dataType === null || dataType === undefined) {
				dispatch(
					GetReportDataAction.failed({
						params: dataType,
						error: { message: 'No datatype supplied' },
					})
				);
			}

			let callCounter = (store.getState().reportReducer as ReportsState).callCounter;
			let params = { callCounter: ++callCounter, dataTypes: dataType };
			dispatch(GetReportDataAction.started(params));

			const reportData = await unitReportsClient.getReportByFilter(
				new FilterDto(filter),
				setCorrectTimeStamp(startDate, false, false),
				setCorrectTimeStamp(endDate, false, true),
				dateType,
				dataType
			);

			if (reportData.reportData && dateType === DateTypes.Month) {
				convertMonthToJS(reportData.reportData);
			}

			dispatch(GetReportDataAction.done({ params: params, result: reportData.reportData || [] }));

			if (params.callCounter === (store.getState().reportReducer as ReportsState).callCounter) {
				// Let's process it into graph- and table data
				getGraphAndTableReport(reportData, dataType, dateType, startDate, endDate)(dispatch);
			}
		} catch (error) {
			notificationService.showErrorMessageWithError((error as any).toString(), undefined, error);
		}
	};
}

function getGraphAndTableReport(
	reportData: UnitReportDto,
	dataType: DataTypes,
	dateType: DateTypes,
	startDate: Date,
	endDate: Date
) {
	return (dispatch: Dispatch<Action<{}>>) => {
		getGraphReport(reportData.reportData || [], dataType, dateType, startDate, endDate)(dispatch);
		getTableReport(reportData.reportData || [], dataType, dateType, startDate, endDate)(dispatch);
	};
}

export function getGraphReport(
	reportData: IReportSingleUnitDto[],
	dataType: DataTypes,
	dateType: DateTypes,
	startDate: Date,
	endDate: Date
) {
	return (dispatch: Dispatch<Action<{}>>) => {
		const graphReport: IGraphReportDto = {
			dates: [],
			values: [],
		};

		const filteredDevices = processReportDataToGraph(
			dateType,
			reportData,
			startDate,
			endDate,
			dataType,
			graphReport
		);

		dispatch(GetGraphReportAction(graphReport));

		return filteredDevices;
	};
}

export function getTableReport(
	reportData: IReportSingleUnitDto[],
	dataType: DataTypes,
	dateType: DateTypes,
	startDate: Date,
	endDate: Date
) {
	return (dispatch: Dispatch<Action<{}>>) => {
		// If there's no raw data, then don't bother processing it.
		if (!reportData || !reportData.length) {
			dispatch(GetTableReportAction({ cols: [], rows: [] }));
			return { totalFilteredCompactors: 0, totalFilteredBalers: 0 };
		}

		const { cols, rows, totalFilteredBalers, totalFilteredCompactors } = getTableColsAndRows(
			reportData,
			dataType,
			dateType,
			startDate,
			endDate
		);

		dispatch(GetTableReportAction({ cols, rows }));
		return { totalFilteredCompactors: totalFilteredCompactors, totalFilteredBalers: totalFilteredBalers };
	};
}

export function setReportsLoadingState(loadingState: boolean) {
	return (dispatch: Dispatch<Action<boolean>>) => {
		dispatch(SetReportsLoadingStateAction(loadingState));
	};
}

export async function getReportTilePreview(settings: ICreateReportTileSettingsDto) {
	let callCounter = (store.getState().reportReducer as ReportsState).reportTileCallCounter;

	return await AsyncOperationBuilder(
		GetReportTilePreviewAction,
		apiClient => (apiClient as DashboardClient).getReportTilePreview(settings as CreateReportTileSettingsDto),
		{ callCounter: ++callCounter, createReportTileSettingsDto: settings },
		dashboardClient
	);
}

export function setReportTileDateType(dateType: DateTypes) {
	return (dispatch: Dispatch<Action<DateTypes>>) => {
		dispatch(SetReportTileDateTypeAction(dateType));
	};
}

export function getTableColsAndRows(
	reportData: IReportSingleUnitDto[],
	dataType: DataTypes,
	dateType: DateTypes,
	startDate: Date,
	endDate: Date
) {
	// If there's no raw data, then don't bother processing it.
	if (!reportData || !reportData.length) {
		return { totalFilteredCompactors: 0, totalFilteredBalers: 0, cols: [], rows: [] };
	}

	const tableReports: ITableReportDto[] = [];
	let rows: Record<string, string>[] = [];
	let cols: (Column & GridColumnExtension)[] = [];

	const getIncrementedDateByDateType = getDateIncrementerImmutable(dateType);
	const getStartOfCurrentDateType = getStartOfDateType(dateType);
	const startOfStartDateByDateType = getStartOfCurrentDateType(startDate);
	const startOfEndDateByDateType = getStartOfCurrentDateType(endDate);

	const headerRow: ITableReportDto = getHeaderRowForReportTable(
		startOfStartDateByDateType,
		startOfEndDateByDateType,
		getIncrementedDateByDateType,
		dateType
	);

	// Iterate through the raw data
	const totalFilteredDevices = processReportDataToTable(
		dateType,
		reportData,
		startDate,
		endDate,
		dataType,
		tableReports
	);

	if (tableReports && tableReports.length > 0) {
		cols = headerRow.dataset!.map((data, i) => ({
			name: data.key || '',
			columnName: data.key || '',
			title: data.value || '',
			width: 120,
		}));

		rows = tableReports.map((tableReport, i) => {
			if (tableReport.dataset) {
				let result: Record<string, string> = {};

				for (let j = 0; j < tableReport.dataset.length; j++) {
					let keyName: string = tableReport.dataset[j].key || j.toString();
					result[keyName] =
						(result[keyName] && tableReport.dataset[j].value
							? (
									Number.parseFloat(result[keyName]) +
									Number.parseFloat(tableReport.dataset[j].value!)
							  ).toString()
							: tableReport.dataset[j].value) || '';
				}
				return result;
			}
			return {};
		});
	}

	return { ...totalFilteredDevices, cols: cols, rows: rows };
}

export function clearReportTilePreview() {
	return (dispatch: Dispatch<Action<void>>) => {
		dispatch(ClearReportTilePreviewAction());
	};
}
