import { IBaleStorageDto, IUnitDetailsDto } from 'api/api';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpsertBaleStorage } from 'state/ducks/machine-details/operations';
import { localized, localizedInterpolation } from 'state/i18n';
import { images } from 'utilities/images';
import { IdDataTuple } from 'utilities/types-util';
import { ConfirmDialog } from '../../../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import BaleSettingCard from './bale-setting-card';
import './bale-settings.scss';
import { baleStorageBuilder, hasValidBaleStorage } from './bale-storage-helper';

type Props = {
	currMachine: IdDataTuple<IUnitDetailsDto, string>;
};
const ResetBalesComponent: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();

	const [isDialogVisible, setDialogVisible] = useState(false);

	const showDialog = () => {
		setDialogVisible(true);
	};

	const hideDialog = () => {
		setDialogVisible(false);
	};

	const onConfirm = async () => {
		const baleStorage: IBaleStorageDto = baleStorageBuilder(props.currMachine.data, { balesOnSite: 0 });

		await UpsertBaleStorage(baleStorage, !props.currMachine.data.baleStorageId)(dispatch);
		hideDialog();
	};

	return (
		<>
			<BaleSettingCard
				title={localized('ResetBalesOnSite')}
				description1={localizedInterpolation('SetBalesOnSiteToXBales', { X: 0 })}
				description2={localized('ThisWillAffectAllLinkedUnits')}
				icon={images.resetBaleCounter}
				bigButton={{ name: localized('reset'), onClick: showDialog }}
				dissableBtn={!hasValidBaleStorage(props.currMachine)}
			/>

			<ConfirmDialog
				onHide={hideDialog}
				onCancel={hideDialog}
				onConfirm={onConfirm}
				header={localized('ResetBalesOnSite')}
				dialogMessage={[
					localizedInterpolation('SetBalesOnSiteToXBales', { X: 0 }),
					localized('ThisWillAffectAllLinkedUnits'),
				]}
				confirmColor={'org-primary'}
				confirmMessage={localized('reset')}
				visible={isDialogVisible}
			/>
		</>
	);
});

export default ResetBalesComponent;
