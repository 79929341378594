import { Routes } from 'state/ducks/routes';

// Local Storage keys
export const AccessTokenKey = 'accessToken';
export const IdTokenKey = 'idToken';
export const RefreshTokenKey = 'refreshToken';
export const RefreshTokenExpireTimeKey = 'refreshTokenExpireTime';
export const AccessTokenExpireTimeKey = 'accessTokenExpireTime';
export const PreAuthRedirectLocationKey = 'preAuthRedirectLocation';

// Auth config
export const AuthConfigKey = 'authConfig';
export const AuthClientId = process.env.REACT_APP_CLIENT_ID!;
export const AuthPostSignInRedirectUri = `${process.env.REACT_APP_BASE_URL}${Routes.Callback}`;
export const AuthPostSignOutRedirectUri = `${process.env.REACT_APP_BASE_URL}${Routes.SignIn}`;
export const AuthClientSecret = process.env.REACT_APP_CLIENT_SECRET!;
export const AuthOpenIdConnectUrl = process.env.REACT_APP_OPEN_ID_CONNECT_URL!;

export const AuthScopes = process.env.REACT_APP_SCOPES!;

// App config
export const IconWifiSignalSize = 21;
export const IconDefaultSize = 24;
export const IconBigSize = 48;
export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const BrandName = process.env.REACT_APP_CUSTOMER_BRAND;
export const InsightsKey = process.env.REACT_APP_INSIGHTS_KEY;
export const StaticMapsKey = process.env.REACT_APP_STATIC_MAPS_KEY;
export const StaticMapUrlMaxSize = 15000;
export const DynamicMapsKey = process.env.REACT_APP_DYNAMIC_MAPS_KEY;
export const StaticMapsImageUrl = process.env.REACT_APP_STATIC_MAPS_IMAGE_URL;
export const DateFourLetterYear = 'YYYY';
export const GooglePlayUrl = process.env.REACT_APP_GOOGLE_PLAY_URL;
export const AppStoreUrl = process.env.REACT_APP_APP_STORE_URL;
export const GoogleAnalyticsKey = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
	? process.env.REACT_APP_GOOGLE_ANALYTICS_KEY
	: '';

// export const Baler = 'Baler';
export const VerticalBaler = 'UnitTypes.VerticalBaler';
export const HorizontalBaler = 'UnitTypes.HorizontalBaler';
export const Compactor = 'UnitTypes.Compactor';
export const OtherEquipment = 'UnitTypes.OtherEquipment';
export const RuniUnitType = 'UnitTypes.RUNI';

export const TableColumnDefaultWidthPx = 170;

//Ids for setting height dynamically
export const FilterBarId = 'filter-bar';
export const MachineTableId = 'machine-table-id';
export const GMapFillPageId = 'gmap-fill-page-id';
export const ReportsTableContainerId = 'reports-table-container';
export const SpecificNotificationContainerId = 'specific-notification-container';
export const ReportsFilterContainerId = 'reports-filter-container';
export const UnitsSelectedRowHeightPx = 59;
export const NavBarHeightPx = 61.5;

export const DateFourLetterYearDashDateIndexLength = 4 + 1;

// Regular expressions
export const EmailRegExp: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([ÆØÅæøåa-zA-Z\-0-9]+\.)+[ÆØÅæøåa-zA-Z]{2,}))$/;
export const GroupNameRegExp: RegExp = /^[[:alnum:]\s&\/+_.-]*$/;
export const YearWithSeparatorRegExp: RegExp = /[^.]?\d{4}.?/;
/**
 * @see https://stackoverflow.com/a/3809435
 */
export const UrlRegExp: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

// Unit color levels
export const UnitFillLevelSemifullLowerLimitPercent = 80;
export const UnitFillLevelFullLowerLimitPercent = 100;

// Notifications
export const SharkCompactorModelName = 'Shark MK3';
export const ContainerModelName = 'Container';
export const TeltonikaIndicatorName = 'TeltonikaIndicator';
export const BalesReadyForPickupNotificationTypeName = 'BalesReadyForPickup';
export const BalesAlmostFinishedNotificationTypeName = 'BaleAlmostFinished';
export const BalesFinishedNotificationTypeName = 'BaleFinished';
export const FillLevelNotificationName = 'FillLevel';
export const AdvancedNotificationName = 'AdvancedNotification';
export const CustomMessageNotificationName = 'CustomMessages';
export const OfflineForXHoursNotificationName = 'OfflineForXHours';
export const OfflineForXHoursThreshold = 12;
export const OfflineForXHoursMaxDevices = 50;

// Date formatting
export const LongDayFormat = 'dddd';
export const LongMonthFormat = 'MMMM';

// Query param keys
export const DashboardTileIdQueryParam = 'dashboardTileId';
export const GPSHistoryIdQueryParam = 'gpsHistoryId';
export const GPSHistoryLocationQueryParam = 'gpsLocation';

export const PoundConversionFactor = 2.2046;

// Dashboard
export const DashboardUpdateInterval = 1000 * 60 * 5; // 5 min
export const DashboardLastSyncInterval = 1000 * 30; // 30 sec
