import { Action, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { LocationState } from 'state/ducks/location/types';
import { ReportsState } from 'state/ducks/reports/types';
import { TableSettingsState } from 'state/ducks/table-settings/types';
import { adminstrationReducer } from '../state/ducks/adminstration/reducer';
import { authReducer } from '../state/ducks/auth/reducer';
import { dashboardReducer } from '../state/ducks/dashboard/reducer';
import { filterDateSearchReducer } from '../state/ducks/filter-date-search/reducer';
import { groupsReducer } from '../state/ducks/groups/reducer';
import { historyTableSettingsReducer } from '../state/ducks/history-table-settings/reducer';
import { machineDetailsReducer } from '../state/ducks/machine-details/reducer';
import { unitHistoryReducer } from '../state/ducks/machine-history/reducer';
import { machineSelectedReducer } from '../state/ducks/machine-selected/reducer';
import { machinesReducer } from '../state/ducks/machines/reducer';
import { notificationCustomMessageReducer } from '../state/ducks/notification-custom-message/reducer';
import { notificationHistoryTableSettingsReducer } from '../state/ducks/notification-history-settings/reducer';
import { notificationHistoryReducer } from '../state/ducks/notification-history/reducer';
import { notificationsReducer } from '../state/ducks/notifications/reducer';
import { profileReducer } from '../state/ducks/profile/reducer';
import { tableUtilityReducer } from '../state/ducks/table-utility/reducer';
import { userPermissionReducer } from '../state/ducks/user-permission/reducer';
import { userSettingReducer } from '../state/ducks/user-settings/reducer';
import { DashboardState } from './ducks/dashboard/types';
import { debtorsReducer } from './ducks/debtors/reducer';
import { duplicateIotHubDecommissionReducer } from './ducks/duplicate-iothub-decommission/reducer';
import { FilterDateSearchState } from './ducks/filter-date-search/types';
import { filterSearchReducer } from './ducks/filter-search/reducer';
import { FilterState } from './ducks/filter-search/types';
import { gpsHistoryTableSettingsReducer } from './ducks/gps-history-setting/reducer';
import { gpsHistoryReducer } from './ducks/gps-history/reducer';
import { locationReducer } from './ducks/location/reducer';
import { partnerAreaReducer } from './ducks/partner-area/reducer';
import { pressureGraphTableSettingsReducer } from './ducks/pressure-graph-history-settings/reducer';
import { releaseNoteReducer } from './ducks/release-notes/reducer';
import { reportReducer } from './ducks/reports/reducer';
import { serviceVideoReducer } from './ducks/service-video/reducer';
import { tableSettingsReducer } from './ducks/table-settings/reducer';
import { teltonikaIndicatorReducer } from './ducks/teltonika-indicator/reducer';
import { unitTrackerReducer } from './ducks/tracker/reducer';
import { unitManufacturerReducer } from './ducks/unit-manufacturer/reducer';
import { unitModelCodeReducer } from './ducks/unit-model-code/reducer';
import { unitModelReducer } from './ducks/unit-model/reducer';
import { unitTypeReducer } from './ducks/unit-type/reducer';
import { unitWeightReducer } from './ducks/unit-weight/reducer';
import { xtelTrackerReducer } from './ducks/xtel-tracker/reducer';

const reducersArray = {
	machinesReducer,
	tableUtilityReducer,
	historyTableSettingsReducer,
	filterDateSearchReducer,
	authReducer,
	unitHistoryReducer,
	machineDetailsReducer,
	profileReducer,
	machineSelectedReducer,
	groupsReducer,
	dashboardReducer,
	userPermissionReducer,
	userSettingReducer,
	notificationsReducer,
	notificationHistoryReducer,
	notificationHistoryTableSettingsReducer,
	adminstrationReducer,
	releaseNoteReducer,
	notificationCustomMessageReducer,
	locationReducer,
	gpsHistoryReducer,
	gpsHistoryTableSettingsReducer,
	pressureGraphTableSettingsReducer,
	deviceTrackerReducer: unitTrackerReducer,
	serviceVideoReducer,
	xtelTrackerReducer,
	debtorsReducer,
	unitWeightReducer,
	partnerAreaReducer,
	duplicateIotHubDecommissionReducer,
	unitModelReducer,
	unitManufacturerReducer,
	unitTypeReducer,
	unitModelCodeReducer,
	teltonikaIndicatorReducer,
};

export type PersistContainer = { config: any; reducer: any };

const persistedLocationReducerConfig: PersistContainer = {
	config: {
		key: 'locationReducer',
		storage,
		whitelist: ['mapType'],
	},
	reducer: locationReducer,
};

const persistedTableSettingsReducerConfig: PersistContainer = {
	config: {
		key: 'tableSettingsReducer',
		storage,
		whitelist: ['selectedMachines'],
	},
	reducer: tableSettingsReducer,
};

const persistedFilterSearchReducer: PersistContainer = {
	config: {
		key: 'filterSearchReducer',
		storage,
		whitelist: ['appliedFilters', 'filter', 'filterOptions'],
	},
	reducer: filterSearchReducer,
};

const persistedFilterDateSearchReducer: PersistContainer = {
	config: {
		key: 'filterDateSearchReducer',
		storage,
		whitelist: ['homeLocationChecked'],
	},
	reducer: filterDateSearchReducer,
};

const persistedDashboardReducer: PersistContainer = {
	config: {
		key: 'dashboardReducer',
		storage,
		whitelist: ['content', 'layout', 'lastUpdated', 'lastFetchDurationMs'],

		// Transform dates back into JS Dates on rehydrate
		// (see: https://github.com/rt2zz/redux-persist/issues/82)
		transforms: [
			createTransform(JSON.stringify, toRehydrate =>
				JSON.parse(toRehydrate, (key, value) =>
					typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
						? new Date(value)
						: value
				)
			),
		],
	},
	reducer: dashboardReducer,
};

const persistedReportReducerConfig: PersistContainer = {
	config: {
		key: 'reportReducer',
		storage,
		whitelist: ['reportType', 'dateType', 'filterStartDate', 'filterEndDate'],
		// Date object are serialized to strings by the persister - make sure to rehydrate as Date Objects
		transforms: [
			createTransform(JSON.stringify, toRehydrate =>
				JSON.parse(toRehydrate, (key, value) =>
					value && typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
						? new Date(value)
						: value
				)
			),
		],
	},
	reducer: reportReducer,
};

// PersistedReducerMap
const persistedReducerMap = {
	locationReducer: persistReducer(persistedLocationReducerConfig.config, persistedLocationReducerConfig.reducer) as (
		s: LocationState,
		a: Action
	) => LocationState & PersistPartial,
	tableSettingsReducer: persistReducer(
		persistedTableSettingsReducerConfig.config,
		persistedTableSettingsReducerConfig.reducer
	) as (s: TableSettingsState, a: Action) => TableSettingsState & PersistPartial,
	reportReducer: persistReducer(persistedReportReducerConfig.config, persistedReportReducerConfig.reducer) as (
		s: ReportsState,
		a: Action
	) => ReportsState & PersistPartial,
	filterSearchReducer: persistReducer(persistedFilterSearchReducer.config, persistedFilterSearchReducer.reducer) as (
		s: FilterState,
		a: Action
	) => FilterState & PersistPartial,
	filterDateSearchReducer: persistReducer(
		persistedFilterDateSearchReducer.config,
		persistedFilterDateSearchReducer.reducer
	) as (s: FilterDateSearchState, a: Action) => FilterDateSearchState & PersistPartial,
	dashboardReducer: persistReducer(persistedDashboardReducer.config, persistedDashboardReducer.reducer) as (
		s: DashboardState,
		a: Action
	) => DashboardState & PersistPartial,
};

export const reducers = combineReducers({
	...reducersArray,
	...persistedReducerMap,
});

declare const window: any;
const store = createStore(
	reducers,
	compose(
		applyMiddleware(thunk),
		// Read: if redux devtools is defined, then return its compose function; else, return the default compose.
		(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
	)
);

const reducerMap = {
	...reducersArray,
	...persistedReducerMap,
};

// AppState is the keys of the reducerMap, with the values returned by each key's respective reducer
export type CoreAppState = {
	[K in keyof typeof reducerMap]: ReturnType<typeof reducerMap[K]>;
};
export type AppState = CoreAppState;

export function getStore(): Store<AppState> {
	return store;
}
export type AppDispatch = typeof store.dispatch;
