import { IUnitWithLocationDto, UnitWithLocationDto } from 'api/api';
import { Accordion, AccordionTab } from 'primereact/accordion';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import Sidebar from 'react-sidebar';
import { Dispatch } from 'redux';
import { setActivelySelectedMachine } from 'state/ducks/location/operations';
import { getMachineDetails } from 'state/ducks/machine-details/operations';
import { Routes } from 'state/ducks/routes';
import history from 'state/history';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { ErrorIdKey, getLocalizedUnitStatusErrorByErrorString } from 'utilities/machine-error-util';
import { getFillLevel } from 'utilities/machine-util';
import { getDateToString } from 'view/shared/components/date-to-string';
import LabelValueCard, {
	addTranslationLabelValueMapToList,
	LabelValueForCardMap,
} from 'view/shared/components/label-value-card';
import ProgressBar from 'view/shared/components/progress-bar/progress-bar';
import { getMostPrioritizedError } from 'view/shared/components/unit-status/unit-status';
import './location-sidebar.scss';

const mapStateToProps = (state: AppState) => {
	return {
		activelySelectedMachine: state.locationReducer.activelySelectedMachine,
		activelySelectedMachines: state.locationReducer.activelySelectedMachines,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setActivelySelectedMachine: (machine?: UnitWithLocationDto) => setActivelySelectedMachine(machine)(dispatch),
	getMachineDetails: async (id: string) => (await getMachineDetails(id))(dispatch),
});

const sidebarStyle = { sidebar: { background: 'white' } };

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const progressBarStyles: React.CSSProperties = { width: 180 };
class LocationSidebar extends React.PureComponent<Props, {}> {
	public render() {
		const sb = document.getElementById('side-bar');

		if (
			sb &&
			(this.props.activelySelectedMachine ||
				(this.props.activelySelectedMachines && this.props.activelySelectedMachines.length))
		) {
			sb.classList.add('z1');
		} else if (sb && sb.classList.contains('z1')) {
			sb.classList.remove('z1');
		}

		const { activelySelectedMachine, activelySelectedMachines } = this.props;

		return (
			<Sidebar
				rootClassName="side-bar"
				rootId="side-bar"
				overlayId="side-bar-overlay"
				pullRight={true}
				sidebarClassName="side-bar-content"
				shadow={true}
				open={
					activelySelectedMachine || (activelySelectedMachines && activelySelectedMachines.length)
						? true
						: false
				}
				onSetOpen={this.resetActivelySelectedMachine}
				styles={sidebarStyle}
				sidebar={
					activelySelectedMachine ? (
						this.getSingleMachineContent(activelySelectedMachine)
					) : activelySelectedMachines && activelySelectedMachines.length ? (
						this.getMultipleMachinesContent()
					) : (
						<></>
					)
				}
			>
				<></>
			</Sidebar>
		); // Sidebar "must have children"
	}

	private getMultipleMachinesContent = () => {
		return (
			<>
				<div className="close-btn">
					<FaTimes
						className="cursor-pointer"
						size={16}
						color="lightgray"
						onClick={this.resetActivelySelectedMachine}
					/>
				</div>
				<div className="sidebar-header-wrapper">
					<span>
						<h4 className="sidebar-header">{localized('MachinesInCluster')}</h4>
					</span>
				</div>
				{this.props.activelySelectedMachines && (
					<Accordion className="margin-top-15px">
						{this.props.activelySelectedMachines.map((machine: IUnitWithLocationDto) => {
							return (
								machine && (
									<AccordionTab
										key={machine.id}
										header={`${machine.unitId}: ${machine.groupName} ${
											machine.unitModel ? '- ' + localizedDynamic(machine.unitModel) : ''
										} ${
											machine.wasteTypeTranslationKey
												? '- ' + localizedDynamic(machine.wasteTypeTranslationKey)
												: ''
										}`}
									>
										<div className="margin-top-15px">
											<LabelValueCard
												labels={this.getTechnicalDetailsPairValues(machine).labels}
												values={this.getTechnicalDetailsPairValues(machine).valuesForLabels}
												valueClassName="label-bold"
											/>
										</div>
										<div className="sidebar-header-wrapper pt-25">
											<span>
												<h4 className="sidebar-header">{localized('LatestActivity')}:</h4>
											</span>
										</div>
										<hr />
										<div className="margin-top-15px">
											<LabelValueCard
												labels={this.getLastActivityDetailsPairValues(machine).labels}
												values={this.getLastActivityDetailsPairValues(machine).valuesForLabels}
												valueClassName="label-bold"
											/>
										</div>
										<div className="sidebar-header-wrapper pt-25">
											<span>
												<h4 className="sidebar-header">{localized('MachineDetails')}: </h4>
											</span>
										</div>
										<hr />
										<div
											className="cursor-pointer link"
											// tslint:disable-next-line:jsx-no-lambda
											onClick={() => this.handleMachineDetailsClicked(machine)}
										>
											{`${localized('GoToMachineDetailsForMoreInfo')}`}
										</div>
									</AccordionTab>
								)
							);
						})}
					</Accordion>
				)}
			</>
		);
	};

	private getSingleMachineContent = (activelySelectedMachine: IUnitWithLocationDto) => {
		const technicalDetailsPairValues =
			activelySelectedMachine && this.getTechnicalDetailsPairValues(activelySelectedMachine);
		const lastActivityDetailsPairValues =
			activelySelectedMachine && this.getLastActivityDetailsPairValues(activelySelectedMachine);
		return (
			<>
				<div className="close-btn">
					<FaTimes
						className="cursor-pointer"
						size={16}
						color="lightgray"
						onClick={this.resetActivelySelectedMachine}
					/>
				</div>
				<div className="sidebar-header-wrapper">
					<span>
						<h4 className="sidebar-header">
							{localized('Machine')}:{' '}
							{this.props.activelySelectedMachine && this.props.activelySelectedMachine.groupName}{' '}
							{this.props.activelySelectedMachine && this.props.activelySelectedMachine.unitModel}
						</h4>
					</span>
				</div>
				<hr />
				<div className="margin-top-15px">
					<LabelValueCard
						labels={technicalDetailsPairValues!.labels}
						values={technicalDetailsPairValues!.valuesForLabels}
						valueClassName="label-bold"
					/>
				</div>
				<div className="sidebar-header-wrapper pt-25">
					<span>
						<h4 className="sidebar-header">{localized('LatestActivity')}:</h4>
					</span>
				</div>
				<hr />
				<div className="margin-top-15px">
					<LabelValueCard
						labels={lastActivityDetailsPairValues!.labels}
						values={lastActivityDetailsPairValues!.valuesForLabels}
						valueClassName="label-bold"
					/>
				</div>
				<div className="sidebar-header-wrapper pt-25">
					<span>
						<h4 className="sidebar-header">{localized('MachineDetails')}: </h4>
					</span>
				</div>
				<hr />
				{/*tslint:disable-next-line:jsx-no-lambda*/}
				<div className="cursor-pointer link" onClick={() => this.handleMachineDetailsClicked()}>
					{`${localized('GoToMachineDetailsForMoreInfo')}`}
				</div>
			</>
		);
	};

	private handleMachineDetailsClicked = (machine?: IUnitWithLocationDto) => {
		if (!machine) {
			if (this.props.activelySelectedMachine) {
				history.push(
					`${Routes.MachineDetails}/${encodeURIComponent(this.props.activelySelectedMachine.unitId!)}`
				);
			}
		} else {
			history.push(`${Routes.MachineDetails}/${encodeURIComponent(machine.unitId!)}`);
		}
	};

	private resetActivelySelectedMachine = () => {
		this.props.setActivelySelectedMachine(undefined);
	};

	private getLastActivityDetailsPairValues(activelySelectedMachine?: IUnitWithLocationDto): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		if (activelySelectedMachine) {
			addTranslationLabelValueMapToList(
				'lastestChange',
				`${activelySelectedMachine.updatedDate && getDateToString(activelySelectedMachine.updatedDate)}`,
				retMap
			);
			addTranslationLabelValueMapToList('gpsLocation', `${activelySelectedMachine.gpsLocation}`, retMap);
		}

		return retMap;
	}

	private getTechnicalDetailsPairValues(activelySelectedMachine?: IUnitWithLocationDto): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		if (activelySelectedMachine) {
			addTranslationLabelValueMapToList(
				'failureMessage',
				(activelySelectedMachine.machineGroupsName &&
					activelySelectedMachine.controllerName &&
					activelySelectedMachine.errorAndSeverities &&
					activelySelectedMachine.errorAndSeverities.length &&
					getLocalizedUnitStatusErrorByErrorString(
						getMostPrioritizedError(activelySelectedMachine.errorAndSeverities) as ErrorIdKey
					)) ||
					`${localized('NoError')}`,
				retMap
			);

			this.addFillLevelLabelValue(activelySelectedMachine, retMap);

			addTranslationLabelValueMapToList('unitId', activelySelectedMachine.unitId, retMap);
			addTranslationLabelValueMapToList('siteUser', activelySelectedMachine.siteUser, retMap);
			addTranslationLabelValueMapToList('location', activelySelectedMachine.location, retMap);
			addTranslationLabelValueMapToList('customerUnitId', activelySelectedMachine.customerUnitId, retMap);
			addTranslationLabelValueMapToList('unitModel', activelySelectedMachine.unitModel, retMap);
			addTranslationLabelValueMapToList(
				'wasteType',
				activelySelectedMachine.wasteTypeTranslationKey
					? localizedDynamic(activelySelectedMachine.wasteTypeTranslationKey)
					: '',
				retMap
			);
			addTranslationLabelValueMapToList(
				'serviceContractType',
				localizedDynamic(activelySelectedMachine.serviceContractTypeTranslationKey || ''),
				retMap
			);
			addTranslationLabelValueMapToList(
				'warrantyExpires',
				activelySelectedMachine.warrantyExpires &&
					`${activelySelectedMachine.warrantyExpires.toLocaleDateString()}`,
				retMap
			);
			addTranslationLabelValueMapToList(
				'ownership',
				activelySelectedMachine.ownershipTranslationKey
					? localizedDynamic(activelySelectedMachine.ownershipTranslationKey)
					: '',
				retMap
			);
		}

		return retMap;
	}

	private addFillLevelLabelValue(activelySelectedMachine: IUnitWithLocationDto, retMap: LabelValueForCardMap) {
		if (activelySelectedMachine.isBaler) {
			addTranslationLabelValueMapToList(
				'balesOnSite',
				activelySelectedMachine.balesOnSite !== undefined &&
					activelySelectedMachine.baleStorageSize !== undefined ? (
					<ProgressBar
						showValue={activelySelectedMachine.balesOnSite != null}
						styles={progressBarStyles}
						value={activelySelectedMachine.balesOnSite}
						maxValue={activelySelectedMachine.baleStorageSize}
					/>
				) : undefined,
				retMap
			);
		} else {
			const fillValues = getFillLevel(
				activelySelectedMachine.machineGroupsName,
				activelySelectedMachine.fillLevel,
				activelySelectedMachine.controllerName
			);

			addTranslationLabelValueMapToList(
				'pickupStatus',
				fillValues && (
					<ProgressBar
						showValue={fillValues.barValue >= 0 ? true : false}
						styles={progressBarStyles}
						value={fillValues.barValue}
						maxValue={fillValues.maxValue}
					/>
				),
				retMap
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationSidebar);
