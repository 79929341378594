import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IProfileDto, IUserSettings, ProfileUnit } from 'api/api';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reactAI } from 'services/telemetry-service';
import { updateProfile } from 'state/ducks/profile/operations';
import { getAvailableServiceTools } from 'state/ducks/service-tool/operations';
import { getUserSettings, upsertUserSettings } from 'state/ducks/user-settings/operations';
import { localized, localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { hasAtLeastPermissionSuperAdmin } from 'utilities/roles-util';
import { cardGeneralClassNames } from 'view/components/table/details/constants';
import BiCheckbox from 'view/shared/components/bi-checkbox/bi-checkbox';
import SetUserLanguagePicker from 'view/shared/components/bi-language-picker/set-user-language-picker';
import BiTitle from 'view/shared/components/bi-title/bi-title';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import WhiteCard, { AvailableElementsForStyling } from 'view/shared/components/cards/white-card';
import LabelValueCard, {
	LabelValueForCardMap,
	addTranslationLabelValueMapToList,
	getLabelValueOrDefaultToLocalizedNoDataString,
} from 'view/shared/components/label-value-card';
import ResponsiveSpacingWrapper from 'view/shared/components/responsive-wrappers/responsive-spacing-wrapper';
import './profile.scss';

const mapStateToProps = (state: AppState) => {
	return {
		profile: state.profileReducer.profile,
		userSetting: state.userSettingReducer.userSettings,
		isSuperAdmin: state.userPermissionReducer.UserGroupPermissions.some(ugp =>
			hasAtLeastPermissionSuperAdmin(ugp.roleType)
		),
	};
};

const mapDispatchToProps = (dispatch: Dispatch, props: {}) => ({
	updateProfile: async (userProfile: IProfileDto) => (await updateProfile(userProfile))(dispatch),
	updateUserSettings: async (userSetting: IUserSettings) => (await upsertUserSettings(userSetting))(dispatch),
	getUserSettings: async () => (await getUserSettings())(dispatch),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const ProfileCardDoubleContainerLength: AvailableElementsForStyling = {
	classNameTitle: '',
	classNameSubtitle: '',
	classNameContainer: `${cardGeneralClassNames.classNameContainer} flex-fill-remaining-space 
      card-width-700-and-margin-spacing flex-direction-column`,
};

const cardAutoWidthContainer = `${cardGeneralClassNames.classNameContainer} card-width-auto fit-content`;

interface State {
	isJE846OrJE911: boolean;
	isJE910: boolean;
	isJE882OrJE982: boolean;
}

class ProfilePage extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		props.getUserSettings();
		this.state = {
			isJE846OrJE911: false,
			isJE910: false,
			isJE882OrJE982: false,
		};
		this.getServiceToolsAvailable();
	}

	public render() {
		const userProfilePairValues = this.getUserProfilePairValues();
		const onJE846OrJE911Click = () => {
			window.open('http://www.je-electronic.dk/publish/je846/BRAMIDAN-YEA16EXC/publish.htm');
		};

		const onJE910Click = () => {
			window.open('http://je-electronic.dk/publish/je910/BRAMIDAN-WGD7X6T8WA/publish.htm');
		};

		const onJE882OrJE982Click = () => {
			window.open('http://je-electronic.dk/publish/je882/Presto-LVYTGD6P/publish.htm');
		};

		const profileCard = (
			<WhiteCard classNameContainer={cardAutoWidthContainer} title={localized('Profile')}>
				<div className="flex-direction-row flex-space-between-row">
					<LabelValueCard
						labels={userProfilePairValues.labels}
						values={userProfilePairValues.valuesForLabels}
					/>
					{/* Profile picture, commented out for now */}
					{/* <img style={imageStyle} className="img-user-picture" src={userProfilePicture} alt="" width={150} height={200} /> */}
				</div>
				<div className="margin-top-15px">
					<h5 className="text-bold">{localized('ServiceTool')}</h5>
					<span className="text-bold">{localized('DownloadServiceToolIntro')}</span>
					{this.state.isJE846OrJE911 && (
						<BiButton
							onClick={onJE846OrJE911Click}
							colorTheme="org-grey"
							containerTheme="normal-button-corners"
							containerClassName="margin-top-15px"
						>
							<span className="color-black">{localized('DownloadBramidanServiceTool')}</span>
						</BiButton>
					)}
					{this.state.isJE910 && (
						<BiButton
							onClick={onJE910Click}
							colorTheme="org-grey"
							containerTheme="normal-button-corners"
							containerClassName="margin-top-15px"
						>
							<span className="color-black">{localized('DownloadBramidanJE910ServiceTool')}</span>
						</BiButton>
					)}
					{this.state.isJE882OrJE982 && (
						<BiButton
							onClick={onJE882OrJE982Click}
							colorTheme="org-grey"
							containerTheme="normal-button-corners"
							containerClassName="margin-top-15px"
						>
							<span className="color-black">{localized('DownloadPrestoServiceTool')}</span>
						</BiButton>
					)}
				</div>
			</WhiteCard>
		);

		return (
			<BiTitle title={localized('Title_Profile')}>
				<ResponsiveSpacingWrapper>
					<div className="margin-horizontal-page-normal">
						<div className="flex-direction-row margin-top-40 margin-bottom-40 flex-space-between-row">
							<h1 className="text-bold">{localized('userProfile')}</h1>
						</div>
						<div className="flex-direction-row">
							<div className="flex-direction-row flex-wrap-responsive">{profileCard}</div>
						</div>
					</div>
				</ResponsiveSpacingWrapper>
			</BiTitle>
		);
	}

	private getServiceToolsAvailable = async () => {
		const serviceToolsAvailable = await getAvailableServiceTools();
		if (serviceToolsAvailable) {
			this.setState({
				isJE846OrJE911: serviceToolsAvailable.isJE846OrJE911,
				isJE882OrJE982: serviceToolsAvailable.isJE882OrJE982,
				isJE910: serviceToolsAvailable.isJE910,
			});
		}
	};

	private getUserProfilePairValues(): LabelValueForCardMap {
		const retMap: LabelValueForCardMap = {
			labels: [],
			valuesForLabels: [],
		};

		addTranslationLabelValueMapToList(
			'firstName',
			this.props.profile.firstName || '',
			retMap,
			true,
			async (inputValue: any) => this.saveProfile('firstName', inputValue)
		);
		addTranslationLabelValueMapToList(
			'lastName',
			this.props.profile.lastName || '',
			retMap,
			true,
			async (inputValue: any) => this.saveProfile('lastName', inputValue)
		);
		addTranslationLabelValueMapToList('email', this.props.profile.email || '', retMap);
		addTranslationLabelValueMapToList(
			'company',
			this.props.profile.company || '',
			retMap,
			true,
			async (inputValue: any) => this.saveProfile('company', inputValue)
		);
		addTranslationLabelValueMapToList(
			'jobTitle',
			this.props.profile.jobtitle || '',
			retMap,
			true,
			async (inputValue: string) => this.saveProfile('jobtitle', inputValue)
		);
		addTranslationLabelValueMapToList(
			'phone',
			this.props.profile.phoneNumber || '',
			retMap,
			true,
			async (someValue: string) => this.saveProfile('phoneNumber', someValue)
		);
		addTranslationLabelValueMapToList(
			'Units',
			getLabelValueOrDefaultToLocalizedNoDataString(
				localizedDynamic(ProfileUnit[this.props.userSetting.profileUnits])
			),
			retMap,
			true,
			value => {
				this.props.updateUserSettings({
					...this.props.userSetting,
					profileUnits: value === localizedDynamic(ProfileUnit[0]) ? 0 : 1,
				});
			},
			'dropdown',
			localizedDynamic(ProfileUnit[this.props.userSetting.profileUnits]), // Callback for editable row (=> would work for either input field (as onClickAway) or bi-multiselect (as onChange))
			[
				{
					label: localizedDynamic(ProfileUnit[0]),
					value: localizedDynamic(ProfileUnit[0]),
				},
				{
					label: localizedDynamic(ProfileUnit[1]),
					value: localizedDynamic(ProfileUnit[1]),
				},
			]
		);

		retMap.labels.push(<span key={`LanguageLabel`}>{localized('language')}</span>);
		retMap.valuesForLabels.push(
			<span key={`LanguageValue`}>
				<SetUserLanguagePicker />
			</span>
		);

		retMap.labels.push(<span key={'TermsAndConditionsLabel'}>{localized('AgreeToTermsAndConditions')}</span>);
		retMap.valuesForLabels.push(
			<span key={`TermsAndConditionsValue`}>
				<BiCheckbox
					onChange={e =>
						this.props.updateUserSettings({
							...this.props.userSetting,
							agreedToTermsAndConditions: e.checked,
						})
					}
					checked={this.props.userSetting.agreedToTermsAndConditions}
					disabled={!this.props.isSuperAdmin}
				/>
			</span>
		);

		return retMap;
	}
	private async saveProfile<T extends keyof IProfileDto>(key: T, value: IProfileDto[T]) {
		let userProfile = { ...this.props.profile };
		userProfile[key] = value;

		await this.props.updateProfile(userProfile);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withAITracking(reactAI.reactPlugin, ProfilePage, 'Profile'));
