import { ITeltonikaIndicatorDto } from 'api/api';
import actionCreatorFactory from 'typescript-fsa';
import { constants } from './constants';

const actionCreator = actionCreatorFactory('teltonikaIndicator');

export const CreateTeltonikaIndicatorAction = actionCreator.async<
	{ teltonikaImei: string; unitId: number },
	ITeltonikaIndicatorDto
>(constants.CREATE_TELTONIKA_INDICATOR);

export const GetTeltonikaIndicatorAction = actionCreator.async<{ serialNumber: string }, ITeltonikaIndicatorDto>(
	constants.GET_TELTONIKA_INDICATOR
);

export const UpdateTeltonikaIndicatorAction = actionCreator.async<
	{ teltonikaImei: string; unitId: number },
	ITeltonikaIndicatorDto
>(constants.UPDATE_TELTONIKA_INDICATOR);

export const DeleteTeltonikaIndicatorAction = actionCreator.async<{ unitId: number }, void>(
	constants.DELETE_TELTONIKA_INDICATOR
);
