import { IBaleStorageDto, IUnitDetailsDto } from 'api/api';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpsertBaleStorage } from 'state/ducks/machine-details/operations';
import { localized } from 'state/i18n';
import { images } from 'utilities/images';
import { IdDataTuple } from 'utilities/types-util';
import BiInputNumber from 'view/shared/components/bi-input-number/bi-input-number';
import { ConfirmDialog } from '../../../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import BaleSettingCard from './bale-setting-card';
import './bale-settings.scss';
import { baleStorageBuilder, hasValidBaleStorage } from './bale-storage-helper';

type Props = {
	currMachine: IdDataTuple<IUnitDetailsDto, string>;
};
const SetBalesOnSiteComponent: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();

	const [isDialogVisible, setDialogVisible] = useState(false);
	const [amountOfBales, setAmountOfBales] = useState(props.currMachine.data.balesOnSite);

	const showDialog = () => {
		setDialogVisible(true);
	};

	const hideDialog = () => {
		setDialogVisible(false);
	};

	const onConfirm = async () => {
		const baleStorage: IBaleStorageDto = baleStorageBuilder(props.currMachine.data, { balesOnSite: amountOfBales });

		await UpsertBaleStorage(baleStorage, !props.currMachine.data.baleStorageId)(dispatch);
		hideDialog();
	};

	const onValueChange = (val: number) => {
		setAmountOfBales(val);
	};

	return (
		<>
			<BaleSettingCard
				title={localized('balesOnSite')}
				description1={localized('SetCurrentAmountOfBalesOnSite')}
				icon={images.editNumberOfBales}
				editButton={{
					text: localized('AmountOfBales'),
					value: props.currMachine.data.balesOnSite,
					onClick: showDialog,
				}}
				dissableBtn={!hasValidBaleStorage(props.currMachine)}
			/>

			<ConfirmDialog
				onHide={hideDialog}
				onCancel={hideDialog}
				onConfirm={onConfirm}
				header={localized('SetBalesOnSite')}
				dialogMessage={localized('SetCurrentAmountOfBalesOnSite')}
				confirmColor={'org-primary'}
				visible={isDialogVisible}
			>
				<div className="flex-center-column">
					<span>{localized('Bales')}: </span>
					<BiInputNumber
						value={amountOfBales}
						onValueChange={onValueChange}
						className={'margin-left-20px'}
						showButtons
					/>
				</div>
			</ConfirmDialog>
		</>
	);
});

export default SetBalesOnSiteComponent;
