import {
	createRowCache,
	DataTypeProvider,
	DataTypeProviderProps,
	Sorting,
	SortingState,
	TableColumnWidthInfo,
	VirtualTableState,
} from '@devexpress/dx-react-grid';
import {
	DragDropProvider,
	Grid,
	Table,
	TableColumnReordering,
	TableColumnResizing,
	TableFixedColumns,
	TableHeaderRow,
	TableSelection,
	VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { FilterDto, IUnitsDto, TableId } from 'api/api';
import * as React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getUserGroupsChildHierarchy } from 'state/ducks/groups/operations';
import { clearMachinesLocations } from 'state/ducks/location/operations';
import {
	clearMachines,
	getMachineCount,
	getMachinesFiltered,
	renewTableKey,
	setLoadingState,
	setMachines,
	setSkipState,
} from 'state/ducks/machines/operations';
import {
	getSettings,
	selectOnlyOneMachine,
	setOrder,
	setSelectedMachines,
	setSize,
	setSorting,
} from 'state/ducks/table-settings/operations';
import { defaultTableSettings } from 'state/ducks/table-settings/reducer';
import { MachineDtoKeys, TableColumnWidthInfoGeneric } from 'state/ducks/table-settings/types';
import { localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';

import { Guid } from 'guid-typescript';
import { TableColumnDefaultWidthPx } from 'utilities/constants';
import { images } from 'utilities/images';
import {
	getControllerNamePrefixedWithType,
	getFillLevel,
	getTranslatedPickupSystem,
	MinDateOnDb,
} from 'utilities/machine-util';
import { biConvertNumberToSeparatedString, roundToDecimals } from 'utilities/number-helpers';
import { getFormattedConnectionStatusAsSignalStrength } from 'utilities/table-utilities';
import { getEmailFromToken } from 'utilities/token-util';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import { DateFormatterModes, getDateToString, getDateToStringPretty } from 'view/shared/components/date-to-string';
import ProgressBar from 'view/shared/components/progress-bar/progress-bar';
import { isNullOrWhitespace } from 'view/shared/components/string-helpers';
import { unitStatusIconOkAndType } from 'view/shared/components/unit-status/constants';
import { getUnitStatusIconForControllerWithErrors } from 'view/shared/components/unit-status/unit-status-icon-util';
import { getWifiStrengthIcon } from 'view/shared/utils/icon-helper';
import BiTextPreview from '../../bi-text/bi-text-preview';
import BiNoData from './bi-no-data/bi-no-data';
import './bi-table.scss';
import BiTableGpsLocation from './extra-columns/bi-table-gps-location';
import MachineInfo from './extra-columns/machine-info';
import MachinesSelectedCheckbox from './extra-columns/machines-selected-checkbox';

interface MachineDtoForTable extends IUnitsDto {
	index: number;
	select: boolean;
}

export const VIRTUAL_PAGE_SIZE = 200;
export const ROW_HEIGHT = 39;

const mapStateToProps = (state: AppState) => {
	return {
		sorting: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.sorting,
		columnOrder: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.columnOrder,
		columnWidths: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.columnWidths,
		columns: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.columns,
		columnsLocked: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.columnsLocked,
		filter: state.filterSearchReducer.appliedFilters,
		machineCount: state.machinesReducer.machineCount,
		loading: state.machinesReducer.loading,
		skip: state.machinesReducer.skip,
		tableKey: state.machinesReducer.machineTableKey,
		userGroups: state.groupsReducer.groups.find(g => g.id === getEmailFromToken()),
		machines: state.machinesReducer.machines,
		selectedLanguage: state.userSettingReducer.userSettings.selectedLanguage,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSorting: (sorting: Sorting[]) => setSorting(sorting)(dispatch),
	setSize: (size: TableColumnWidthInfoGeneric<MachineDtoKeys>[]) => setSize(size)(dispatch),
	setOrder: (order: MachineDtoKeys[]) => setOrder(order)(dispatch),
	getMachinesFiltered: async (filter?: FilterDto, skip?: number, take?: number, sorting?: Sorting) =>
		(await getMachinesFiltered(filter, skip, take, sorting))(dispatch),
	setMachines: (machines: IUnitsDto[], skip: number) => setMachines(machines, skip)(dispatch),
	setSelectedMachines: (machineIds: number[]) => setSelectedMachines(machineIds)(dispatch),
	getMachineCount: async (filter?: FilterDto) => await getMachineCount(filter)(dispatch),
	clearMachines: () => clearMachines()(dispatch),
	getSettings: async () => (await getSettings())(dispatch),
	setSkipState: (skip: number) => setSkipState(skip)(dispatch),
	setLoadingState: (loading: boolean) => setLoadingState(loading)(dispatch),
	renewTableKey: () => renewTableKey()(dispatch),
	getUserGroupsChildHierarchy: async () => (await getUserGroupsChildHierarchy())(dispatch),
	selectOnlyOneMachine: (deviceId: number) => selectOnlyOneMachine(deviceId)(dispatch),
	clearMachinesLocations: () => clearMachinesLocations()(dispatch),
});

type dataType = IUnitsDto[];
type dataElementType = dataType[0];

interface PropsFromParent {
	sortingColumnExtensions?: SortingState.ColumnExtension[];
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & PropsFromParent;

interface State {
	statusColumns: MachineDtoKeys[];
	signalColumns: MachineDtoKeys[];
	dateColumns: MachineDtoKeys[];
	dateShortColumns: MachineDtoKeys[];
	dateFriendlyColumns: MachineDtoKeys[];
	fillLevelColumns: MachineDtoKeys[];
	translateColumns: MachineDtoKeys[];
	infoColumns: MachineDtoKeys[];
	controllerColumns: MachineDtoKeys[];
	textPreviewWithTooltipColumns: MachineDtoKeys[];
	fixedRightColumns: MachineDtoKeys[];
	fixedLeftColumns: any[];
	selectColumns: MachineDtoKeys[];
	connectionStatusAsSignalStrength: MachineDtoKeys[];
	gpsLocationColumns: MachineDtoKeys[];
	columnExtensions: Table.ColumnExtension[] | undefined;
	manufacturerColumns: MachineDtoKeys[];
	avgCyclesEmptiedThirtyDays: MachineDtoKeys[];
	homeLocationColums: MachineDtoKeys[];
	balesReadyForPickupColumns: MachineDtoKeys[];
	fullCounter: MachineDtoKeys[];
	totalRunningHours: MachineDtoKeys[];
	totalNumberOfCycles: MachineDtoKeys[];
	cyclesSinceLastOutput: MachineDtoKeys[];
	pressSeries: MachineDtoKeys[];
	pressSeriesSinceLastOutput: MachineDtoKeys[];
	daysSinceInstallation: MachineDtoKeys[];
	trackerBatteryLevel: MachineDtoKeys[];
}

const getRowId = (row: IUnitsDto) => {
	return Guid.create().toString();
};

const TableHeaderContent = ({ column, children, ...restProps }: any) => {
	if (column.name === 'select') {
		return (
			<TableHeaderRow.Content column={column} {...restProps} className="bi-table-header">
				<MachinesSelectedCheckbox isSelectAll={true} />
			</TableHeaderRow.Content>
		);
	} else if (column.name === 'moreInfo') {
		return (
			<TableHeaderRow.Content column={column} {...restProps} className="bi-table-header">
				{children}
			</TableHeaderRow.Content>
		);
	} else {
		return (
			<BiTooltip inTable={true} overlay={localizedDynamic('Tooltip_' + column.name)}>
				<TableHeaderRow.Content column={column} {...restProps} className="bi-table-header">
					{children}
				</TableHeaderRow.Content>
			</BiTooltip>
		);
	}
};

const TableRow = (obj: any) => (
	<VirtualTable.Row {...obj} className={obj.row.index % 2 === 1 ? 'color-background' : ''} />
);

const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-table-striped" />;

const HeaderComponent = ({ ...restProps }) => {
	return <VirtualTable.TableHead {...restProps} className="table-header" />;
};

const GenericTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider {...props} />;

const ConnectionStatusAsSignalStrengthFormatter = (obj: DataTypeProvider.ValueFormatterProps) =>
	getFormattedConnectionStatusAsSignalStrength(obj.row as dataElementType);

const SelectFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
	const unitDto = obj.row as dataElementType;
	return <MachinesSelectedCheckbox deviceId={unitDto.id} />;
};

const SignalFormatter = (obj: { value: number }) => getWifiStrengthIcon(obj.value);

const getIsMinDate = (value: Date | undefined): boolean => MinDateOnDb.toUTCString() === value?.toUTCString();

const DateShortFormatter = (obj: { value: Date }) =>
	getIsMinDate(obj.value) ? <span /> : <span>{getDateToString(obj.value)}</span>;

const DateFormatter = (obj: { value: Date }) =>
	getIsMinDate(obj.value) ? <span /> : <span>{getDateToString(obj.value, DateFormatterModes.withMinutes)}</span>;

const DateFriendlyFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
	const currDate = new Date();
	const unitDto = obj.row as dataElementType;
	const cappedDate: Date | undefined =
		unitDto.lastestChange && unitDto.lastestChange > currDate ? currDate : unitDto.lastestChange;

	return getIsMinDate(unitDto.lastestChange) ? <span /> : <span>{getDateToStringPretty(cappedDate)}</span>;
};

const FillLevelFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const unitDto = obj.row as dataElementType;

	const fillValues = getFillLevel(unitDto.unitTypeName, unitDto.fillLevel, unitDto.controllerName);
	if (
		(fillValues && fillValues.barValue && fillValues.maxValue && fillValues.barValue / fillValues.maxValue < 0) ||
		unitDto.fillLevel === -1
	) {
		return <div className={'textAlignCenter'}>-</div>;
	}

	return <ProgressBar value={fillValues.barValue} maxValue={fillValues.maxValue} />;
};

const BalesReadyForPickupFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const unitDto = obj.row as dataElementType;

	if (unitDto.balesOnSite == null || unitDto.baleStorageSize == null) {
		return <div className={'textAlignCenter'}>-</div>;
	}
	return (
		<div className="flex-container">
			{unitDto.countLinkedBalers && unitDto.countLinkedBalers > 1 && (
				<img src={images.link} className="td-icon" alt={'Bale Link'} />
			)}
			<ProgressBar value={unitDto.balesOnSite} maxValue={unitDto.baleStorageSize} classes="flex-1" />
		</div>
	);
};

const PickupSystemFormatter = (obj: DataTypeProvider.ValueFormatterProps) => (
	<span>{getTranslatedPickupSystem(obj.value)}</span>
);

const InfoFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const unitDto = obj.row as dataElementType;
	return <MachineInfo id={unitDto.unitId || ''} />;
};

const StatusFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const unitDto = obj.row as dataElementType;
	if (unitDto.errorAndSeverities && unitDto.errorAndSeverities.length > 0 && unitDto.controllerName) {
		const iconAndType = getUnitStatusIconForControllerWithErrors(unitDto.errorAndSeverities);
		return iconAndType.icon;
	}
	const icon = unitStatusIconOkAndType;
	return <div>{icon.icon}</div>;
};

const NumberFormatter = (obj: { value: number | null }) => (
	<span>{obj.value !== null ? biConvertNumberToSeparatedString(obj.value) : ''}</span>
);

const ManufacturerFormatter = (obj: { value: string }) => (
	<span>{obj.value !== 'Other Manufacturer' ? obj.value : localizedDynamic('otherManufacturer')}</span>
);

const ControllerFormatter = (obj: { value: string }) => <span>{getControllerNamePrefixedWithType(obj.value)}</span>;

const TextPreviewWithTooltipFormatter = (obj: { value: string }) => <BiTextPreview fullText={obj.value} />;

const AvgCyclesEmptiedThirtyDaysFormatter = (obj: { value: number }) => <span>{roundToDecimals(obj.value, 0)}</span>;

const PercentageFormatter = (obj: { value: number | null }) => (
	<span>{obj.value !== null ? `${biConvertNumberToSeparatedString(obj.value)} %` : ''}</span>
);

const rootStyle: React.CSSProperties = { height: '100%' };

const Root = (props: Grid.RootProps) => <Grid.Root {...props} style={rootStyle} />;

const isColumnSortingApplied = (columnName: any, sorting: any[]) =>
	sorting.findIndex(sortingItem => sortingItem.columnName === columnName) > -1;

class BiTable extends React.PureComponent<Props, State> {
	private currMachine: dataElementType | undefined;
	private cache = createRowCache(VIRTUAL_PAGE_SIZE);

	constructor(props: Props) {
		super(props);

		let columnExtensions = this.getColumnExtension();
		let fixedLeft = this.getFixedLeftColumns();

		this.state = {
			selectColumns: ['select'],
			statusColumns: ['errorAndSeverities'],
			signalColumns: ['signalStrength'],
			dateColumns: ['lastestChange', 'uploadedDate'],
			dateShortColumns: ['lastService', 'warrantyExpires', 'installationDate', 'nextService'],
			dateFriendlyColumns: ['updatedDateFriendly'],
			fillLevelColumns: ['fillLevel'],
			translateColumns: [
				'pickupSystem',
				'wasteTypeTranslationKey',
				'ownershipTranslationKey',
				'serviceContractTypeTranslationKey',
			],
			infoColumns: ['moreInfo'],
			controllerColumns: ['controllerName'],
			textPreviewWithTooltipColumns: ['comments'],
			fixedRightColumns: ['moreInfo'],
			connectionStatusAsSignalStrength: ['connectionStatusAsSignalStrength'],
			gpsLocationColumns: ['gpsLocation'],
			fixedLeftColumns: fixedLeft,
			columnExtensions: columnExtensions,
			manufacturerColumns: ['manufacturer'],
			avgCyclesEmptiedThirtyDays: ['averageCyclesPerUnitEmptiedThirtyDays'],
			homeLocationColums: ['homeLocation'],
			balesReadyForPickupColumns: ['balesOnSite'],
			fullCounter: ['unitsEmptied'],
			totalRunningHours: ['totalRunningHours'],
			totalNumberOfCycles: ['totalNumberOfCycles'],
			cyclesSinceLastOutput: ['cyclesSinceLastOutput'],
			pressSeries: ['pressSeries'],
			pressSeriesSinceLastOutput: ['pressSeriesSinceLastOutput'],
			daysSinceInstallation: ['daysSinceInstalled'],
			trackerBatteryLevel: ['trackerBatteryLevel'],
		};
	}

	public componentDidUpdate(prevProps: Props) {
		if (!isEqual(prevProps.filter, this.props.filter) || !isEqual(prevProps.sorting, this.props.sorting)) {
			this.invalidateTableCache();
			this.props.renewTableKey();
		} else if (
			this.props.machines &&
			this.props.machines.length &&
			!(this.props.skip === null || this.props.skip === undefined) &&
			prevProps.skip !== this.props.skip
		) {
			const machinesFromCache: IUnitsDto[] = this.cache.getRows(this.props.skip, 1);
			if (!machinesFromCache || !machinesFromCache.length) {
				this.cache.setRows(this.props.skip, this.props.machines);
			}
		}

		if (!isEqual(prevProps.columns, this.props.columns)) {
			this.setState({ columnExtensions: this.getColumnExtension() });
		}

		if (!isEqual(prevProps.columnsLocked, this.props.columnsLocked)) {
			this.setState({ fixedLeftColumns: this.getFixedLeftColumns() });

			if (this.props.columnOrder) this.changeColumnOrder(this.props.columnOrder);
		}
	}

	public render() {
		// As per dx-react-core 2.0.2, <TableColumnResizing> will throw an error if one or more columns are missing a width.
		// Scenario: A column is renamed, or a new column is added.
		const {
			updatedColumnWidths,
			updatedColumnOrders,
		} = this.getColumnWidthsAndColumnOrdersWithDefaultValuesIfNew();

		let data: MachineDtoForTable[] = [];
		if (this.props.machines) {
			data = this.props.machines.map((d, index) => {
				return { ...d, index, select: index === 0 };
			});
		}

		let columnsCopy = this.props.columns ? [...this.props.columns] : [];
		for (let index = 0; index < columnsCopy.length; index++) {
			if (columnsCopy[index].name === 'containerSize') {
				columnsCopy[index].title = localizedDynamic(columnsCopy[index].name) + ' (m³)';
			} else {
				columnsCopy[index].title = localizedDynamic(columnsCopy[index].name);
			}
		}

		let selectIndex = columnsCopy.findIndex(s => s.name === 'select');

		if (selectIndex > -1) {
			while (selectIndex > -1) {
				columnsCopy.splice(selectIndex, 1);
				selectIndex = columnsCopy.findIndex(s => s.name === 'select');
			}
			columnsCopy.push({ name: 'select', title: 'Select' });
		}
		const content = (
			<Grid
				rows={data || []}
				columns={columnsCopy}
				getRowId={getRowId}
				rootComponent={Root}
				key={this.props.tableKey + this.props.columns?.length}
			>
				<VirtualTableState
					loading={false}
					totalRowCount={this.props.machineCount || 0}
					pageSize={VIRTUAL_PAGE_SIZE}
					skip={this.props.skip || 0}
					getRows={this.getRemoteRows}
					infiniteScrolling={false}
				/>
				<GenericTypeProvider for={this.state.selectColumns} formatterComponent={SelectFormatter} />
				<GenericTypeProvider for={this.state.statusColumns} formatterComponent={StatusFormatter} />
				<GenericTypeProvider for={this.state.signalColumns} formatterComponent={SignalFormatter} />
				<GenericTypeProvider for={this.state.dateColumns} formatterComponent={DateFormatter} />
				<GenericTypeProvider for={this.state.dateShortColumns} formatterComponent={DateShortFormatter} />
				<GenericTypeProvider for={this.state.dateFriendlyColumns} formatterComponent={DateFriendlyFormatter} />
				<GenericTypeProvider for={this.state.fillLevelColumns} formatterComponent={FillLevelFormatter} />
				<GenericTypeProvider
					for={this.state.balesReadyForPickupColumns}
					formatterComponent={BalesReadyForPickupFormatter}
				/>
				<GenericTypeProvider for={this.state.controllerColumns} formatterComponent={ControllerFormatter} />
				<GenericTypeProvider for={this.state.translateColumns} formatterComponent={PickupSystemFormatter} />
				<GenericTypeProvider for={this.state.infoColumns} formatterComponent={InfoFormatter} />
				<GenericTypeProvider for={this.state.manufacturerColumns} formatterComponent={ManufacturerFormatter} />
				<GenericTypeProvider
					for={this.state.gpsLocationColumns}
					formatterComponent={this.GpsLocationFormatter}
				/>
				<GenericTypeProvider
					for={this.state.homeLocationColums}
					formatterComponent={this.HomeLocationFormatter}
				/>
				<GenericTypeProvider
					for={this.state.textPreviewWithTooltipColumns}
					formatterComponent={TextPreviewWithTooltipFormatter}
				/>
				<GenericTypeProvider
					for={this.state.connectionStatusAsSignalStrength}
					formatterComponent={ConnectionStatusAsSignalStrengthFormatter}
				/>
				<GenericTypeProvider
					for={this.state.avgCyclesEmptiedThirtyDays}
					formatterComponent={AvgCyclesEmptiedThirtyDaysFormatter}
				/>
				<GenericTypeProvider for={this.state.trackerBatteryLevel} formatterComponent={PercentageFormatter} />

				<GenericTypeProvider for={this.state.fullCounter} formatterComponent={NumberFormatter} />
				<GenericTypeProvider for={this.state.totalRunningHours} formatterComponent={NumberFormatter} />
				<GenericTypeProvider for={this.state.totalNumberOfCycles} formatterComponent={NumberFormatter} />
				<GenericTypeProvider for={this.state.cyclesSinceLastOutput} formatterComponent={NumberFormatter} />
				<GenericTypeProvider for={this.state.pressSeries} formatterComponent={NumberFormatter} />
				<GenericTypeProvider for={this.state.pressSeriesSinceLastOutput} formatterComponent={NumberFormatter} />
				<GenericTypeProvider for={this.state.daysSinceInstallation} formatterComponent={NumberFormatter} />

				<SortingState
					sorting={this.props.sorting}
					defaultSorting={this.props.sorting}
					onSortingChange={this.changeSorting}
					columnExtensions={this.props.sortingColumnExtensions}
				/>
				<VirtualTable
					tableComponent={TableComponent}
					headComponent={HeaderComponent}
					height="auto"
					noDataCellComponent={this.NoDataCellComponent}
					rowComponent={TableRow}
					estimatedRowHeight={ROW_HEIGHT}
					columnExtensions={this.state.columnExtensions}
				/>
				<DragDropProvider />
				<TableColumnReordering onOrderChange={this.changeColumnOrder} order={updatedColumnOrders} />
				<TableColumnResizing
					onColumnWidthsChange={this.changeColumnWidths}
					columnWidths={updatedColumnWidths}
				/>
				<TableHeaderRow showSortingControls={true} contentComponent={TableHeaderContent} />
				<TableFixedColumns
					leftColumns={this.state.fixedLeftColumns}
					rightColumns={this.state.fixedRightColumns}
				/>
			</Grid>
		);

		return content;
	}

	private changeSorting = (sorting: Sorting[]) => {
		const { columnName } = sorting[0];
		if (this.props.sorting && !isColumnSortingApplied(columnName, this.props.sorting)) {
			sorting[0].direction = 'desc';
		}
		this.props.clearMachines();
		this.props.setSorting(sorting);
	};

	private getColumnExtension() {
		let columnExtensions: Table.ColumnExtension[] = [];
		this.props.columns?.forEach(column => {
			columnExtensions.push({ columnName: column.name });
		});

		return columnExtensions;
	}

	private getFixedLeftColumns(): (MachineDtoKeys | symbol)[] {
		let fixedLeft: (MachineDtoKeys | symbol)[] = [TableSelection.COLUMN_TYPE, 'unitId', 'select'];

		if (this.props.columnsLocked) {
			fixedLeft.push(...this.props.columnsLocked.filter(colLock => !fixedLeft.includes(colLock)));
		}

		return fixedLeft;
	}

	private invalidateTableCache() {
		this.cache.invalidate();
	}

	private getRemoteRows = async (skip: number, take: number) => {
		this.props.setLoadingState(true);

		const machinesFromCache: IUnitsDto[] = this.cache.getRows(skip, take);
		if (machinesFromCache && machinesFromCache.length) {
			this.props.setMachines(machinesFromCache, skip);
		} else {
			let sorting = this.props.sorting && this.props.sorting[0];
			await this.props.getMachinesFiltered(new FilterDto(this.props.filter), skip, take, sorting);
		}

		this.props.setLoadingState(false);
	};

	private NoDataCellComponent = (restProps: Table.NoDataCellProps): JSX.Element => {
		return <BiNoData noDataCellProps={restProps} tableId={TableId.Machines} />;
	};

	private changeColumnWidths = async (columnWidths: TableColumnWidthInfo[]) => {
		await this.props.setSize(columnWidths as TableColumnWidthInfoGeneric<MachineDtoKeys>[]);
	};

	private changeColumnOrder = (newOrder: any[]) => {
		let order: MachineDtoKeys[] = ['select', 'unitId'];

		if (this.props.columnsLocked) order.push(...newOrder.filter(noc => this.props.columnsLocked?.includes(noc)));

		(newOrder as MachineDtoKeys[]).forEach(columnName => {
			if (columnName !== 'moreInfo') {
				order.push(columnName);
			}
		});
		order.push('moreInfo');
		order = order.filter((col, index) => order.indexOf(col) === index);
		this.props.setOrder(order);
	};

	private getColumnWidthsAndColumnOrdersWithDefaultValuesIfNew = () => {
		let newColumnWidths: TableColumnWidthInfoGeneric<MachineDtoKeys>[] = [];
		let newColumnOrders: string[] = [];
		this.props.columns?.forEach(col => {
			if (!this.props.columnWidths?.some(colWidth => colWidth.columnName === col.name)) {
				newColumnWidths.push({
					columnName: col.name,
					width: TableColumnDefaultWidthPx, // default width
				});
				newColumnOrders.push(col.name);
			}
		});

		let updatedColumnWidths: typeof newColumnWidths = this.props.columnWidths || defaultTableSettings.columnWidths;
		let updatedColumnOrders: typeof newColumnOrders = this.props.columnOrder || defaultTableSettings.columnOrder;
		if (newColumnWidths.length) {
			updatedColumnWidths = updatedColumnWidths.concat(newColumnWidths);
			updatedColumnOrders = updatedColumnOrders.concat(newColumnOrders);
		}

		if (updatedColumnOrders.some(s => s === 'select')) {
			let updatedColumnsCopy = [...updatedColumnOrders];
			updatedColumnsCopy = updatedColumnsCopy.filter(name => name !== 'select');
			let order: string[] = ['select'];
			order = order.concat(updatedColumnsCopy);
			updatedColumnOrders = order;
		}

		if (updatedColumnOrders.some(s => s === 'moreInfo')) {
			updatedColumnOrders.splice(
				updatedColumnOrders.findIndex(c => c === 'moreInfo'),
				1
			);
			updatedColumnOrders.push('moreInfo');
		}

		return { updatedColumnWidths, updatedColumnOrders };
	};

	private setAsSelectedMachine = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		currMachineClicked?: IUnitsDto
	) => {
		this.props.selectOnlyOneMachine(currMachineClicked ? currMachineClicked.id : this.currMachine!.id);
		this.props.clearMachinesLocations();
	};

	private GpsLocationFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
		const currMachine = obj.row as dataElementType;
		this.currMachine = currMachine;
		if (!isNullOrWhitespace(currMachine.latitude) && !isNullOrWhitespace(currMachine.longitude)) {
			const address = currMachine.latitude + ', ' + currMachine.longitude;

			return (
				<BiTableGpsLocation
					machine={currMachine}
					setAsSelectedMachine={this.setAsSelectedMachine}
					value={address}
				/>
			);
		} else {
			return <div />;
		}
	};

	private HomeLocationFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
		const currMachine = obj.row as dataElementType;
		this.currMachine = currMachine;
		if (!isNullOrWhitespace(currMachine.homeLatitude) && !isNullOrWhitespace(currMachine.homeLongitude)) {
			const address = currMachine.homeLatitude + ', ' + currMachine.homeLongitude;

			return <div>{address}</div>;
			// NOTE:: As for now, this logic is replaced by a simple string (return above).
			// It happens to cause confussiuon, when the user is send to a map with no marked point at the location.
			// As for now the time havent been to implement a unique marker, to mark the units hame location on a map.
			// Therefore it's simply no longer an option to click on these coordinates.
			// return (
			// 	<BiTableGpsLocation
			// 		machine={currMachine}
			// 		setAsSelectedMachine={this.setAsSelectedMachine}
			// 		value={address}
			// 	/>
			// );
		} else {
			return <div />;
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BiTable);
