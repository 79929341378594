import { Action } from 'redux';
import { notificationService } from 'services/notification-service';
import { localized } from 'state/i18n';
import { isType } from 'typescript-fsa';
import { deleteValueInArrayCustomId, upsertValueInArrayCustomId } from 'utilities/reducer-helpers';
import * as actions from './actions';
import { TeltonikaIndicatorState } from './types';

export const initialState: TeltonikaIndicatorState = {
	teltonikaIndicators: [],
};

export function teltonikaIndicatorReducer(
	state: typeof initialState = initialState,
	action: Action
): typeof initialState {
	if (isType(action, actions.CreateTeltonikaIndicatorAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			teltonikaIndicators: upsertValueInArrayCustomId(
				state.teltonikaIndicators,
				action.payload.result,
				indicator => indicator.unitId
			),
		};
	} else if (isType(action, actions.GetTeltonikaIndicatorAction.done)) {
		return {
			...state,
			teltonikaIndicators: upsertValueInArrayCustomId(
				state.teltonikaIndicators,
				action.payload.result,
				indicator => indicator.unitId
			),
		};
	} else if (isType(action, actions.UpdateTeltonikaIndicatorAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			teltonikaIndicators: upsertValueInArrayCustomId(
				state.teltonikaIndicators,
				action.payload.result,
				indicator => indicator.unitId
			),
		};
	} else if (isType(action, actions.DeleteTeltonikaIndicatorAction.done)) {
		notificationService.showSuccessMessage(localized('Saved'));
		return {
			...state,
			teltonikaIndicators: deleteValueInArrayCustomId(
				state.teltonikaIndicators,
				action.payload.params.unitId,
				indicator => indicator.unitId
			),
		};
	}

	return state;
}
