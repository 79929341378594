import { localized, localizedDynamic } from 'state/i18n';
import { Compactor, HorizontalBaler, RuniUnitType, TeltonikaIndicatorName, VerticalBaler } from './constants';
import { IdDataTuple, IdNumber } from './types-util';

export const MinDateOnDb = new Date('0001-01-01T00:00:00.000Z');
export const MaxFillLevelCompactor = 100; // shown as percentage
export const MaxFillLevelPercent = 100;

export function getFillLevel(
	unitTypeName: string | undefined,
	fillLevel: number | undefined,
	controllerName: string | undefined
) {
	let barValue, maxValue;

	if (unitTypeName === Compactor || controllerName === TeltonikaIndicatorName) {
		barValue = fillLevel ? fillLevel : 0;
		maxValue = MaxFillLevelCompactor;
	} else {
		barValue = -1;
		maxValue = MaxFillLevelCompactor;
	}

	return { barValue, maxValue };
}

export function getTranslatedPickupSystem(pickupSystem?: string): string {
	// const ps = localizedDynamic(`PickupSystem_${pickupSystem || 'None'}`);
	return pickupSystem ? localizedDynamic(pickupSystem) : ' ';
}

export function getControllerNamePrefixedWithType(name: string): string {
	switch (name) {
		case 'JE882':
		case 'JE911':
		case 'JE846':
		case 'JE982':
			return `${localized('Controller')} ${name}`;
		case 'JE910':
			return `${localized('Link')} ${name}`;
		default:
			return name;
	}
}

export function getFormattedFillLevelCompactor(level: number) {
	return `${level === -1 ? '?' : level} %`;
}

export function getFormattedFillLevelBaler(level: number, maxCapacity: number) {
	return `${level}/${maxCapacity}`;
}

export function getMachineFromIdDataMapOrDefault<T extends IdNumber>(
	data: IdDataTuple<T, string>[],
	id: string,
	resultType: new () => T
): IdDataTuple<T, string> {
	const defaultVal: IdDataTuple<T, string> = {
		id: '',
		data: new resultType(),
	};
	const a = data.find(curr => curr.id === id) || defaultVal;
	return a;
}

// ToDo:: Remove function - Should only exist on API
export function isBaler(machineGroupsName: string | undefined) {
	return (
		machineGroupsName === VerticalBaler ||
		machineGroupsName === HorizontalBaler ||
		machineGroupsName === RuniUnitType
	);
}
