import { FileResponse, FilterDto, MachineExcelQuery } from 'api/api';
import fileSaver from 'file-saver';
import { AppState } from 'state/store';
import { Button } from 'primereact/button';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { notificationService } from 'services/notification-service';
import { unitExcelClient } from 'state/ducks/api-clients';
import { setRefreshRate } from 'state/ducks/table-utility/operations';
import { availableRefreshRates, availableRefreshRatesKeys, refreshRateKeys } from 'state/ducks/table-utility/types';
import { localized } from 'state/i18n';
import { images } from 'utilities/images';
import { MimeTypes } from 'utilities/mime-type-helper';
import { hasAtLeastPermissionAdmin } from 'utilities/roles-util';
import { Spinner } from 'view/components/spinner/spinner';
import BiTooltip from 'view/shared/components/bi-tooltip/bi-tooltip';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import EditColumn from './edit-column';
import MoveMachine from './move-machine';
import './table-utility.scss';
import { MachineDtoKeys } from 'state/ducks/table-settings/types';
import { defaultTableSettings } from 'state/ducks/table-settings/reducer';
import { getUserSettings } from 'state/ducks/user-settings/operations';
import { getSelectedLanguage } from 'utilities/language-util';

const buttonClassName = 'p-button-custom p-button-secondary';

const mapStateToProps = (state: AppState) => {
	const isAdminOrHigher =
		state.userPermissionReducer.UserGroupPermissions.find(g => hasAtLeastPermissionAdmin(g.roleType)) !== undefined;

	return {
		refreshRate: state.tableUtilityReducer.refreshRates.home,
		columns: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.columns,
		columnsOrder: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.columnOrder,
		columnsSorting: state.tableSettingsReducer.tableSettingsList.find(
			ts => ts.tableSettings.id === state.tableSettingsReducer.currentTableSettingId
		)?.sorting,
		appliedFilters: state.filterSearchReducer.appliedFilters,
		isAdminOrHigher,
		selectedMachines: state.tableSettingsReducer.selectedMachines,
		userSetting: state.userSettingReducer.userSettings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setRefreshRate: (id: refreshRateKeys, rate: availableRefreshRatesKeys) => setRefreshRate(id, rate)(dispatch),
	getUserSettings: async () => (await getUserSettings())(dispatch),
});

interface AppProps {
	idOfRefreshRateOnRedux: refreshRateKeys;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

interface State {
	editColumnsDialogVisible: boolean;
	moveMachinesDialogVisible: boolean;
	// dialogVisible: boolean;
	loading: boolean;
}

class TableUtility extends React.PureComponent<Props, State> {
	public constructor(props: Props) {
		super(props);

		props.getUserSettings();
		this.state = {
			moveMachinesDialogVisible: false,
			loading: false,
			editColumnsDialogVisible: false,
		};
	}

	public handleEditColumn = () => {
		this.setState({ editColumnsDialogVisible: true });
	};

	public handleHideEditColumnDialog = () => {
		this.setState({ editColumnsDialogVisible: false });
	};

	public handleMoveMachine = () => {
		this.setState({ moveMachinesDialogVisible: true });
	};
	public handleHideMoveMachine = () => {
		this.setState({ moveMachinesDialogVisible: false });
	};

	public handleExcelFile = async () => {
		this.setState({ loading: true });
		try {
			let machineExcelQuery = new MachineExcelQuery();
			machineExcelQuery.filter = new FilterDto(this.props.appliedFilters);
			machineExcelQuery.machineIds = this.props.selectedMachines;

			let orderFunction = (col1: MachineDtoKeys, col2: MachineDtoKeys) => {
				let order: MachineDtoKeys[] = this.props.columnsOrder || defaultTableSettings.columnOrder;
				return order.indexOf(col1) - order.indexOf(col2);
			};

			let orderedColumns: MachineDtoKeys[] =
				this.props.columns?.map(col => col.name) || defaultTableSettings.columnOrder;

			if (orderedColumns.includes('lastestChange') && orderedColumns.includes('updatedDateFriendly'))
				//Evaluates to same column in excel - should not occur twice
				orderedColumns = orderedColumns.filter(key => key !== 'lastestChange');
			orderedColumns = orderedColumns.sort(orderFunction);

			const fileResponse: FileResponse | null | void = await unitExcelClient.downloadFile(
				machineExcelQuery,
				orderedColumns,
				Intl.DateTimeFormat().resolvedOptions().timeZone,
				getSelectedLanguage(this.props.userSetting.selectedLanguage),
				this.props.columnsSorting && this.props.columnsSorting[0].columnName,
				this.props.columnsSorting && this.props.columnsSorting[0].direction
			);

			if (fileResponse) {
				const blob = new Blob([fileResponse.data], {
					type: MimeTypes.ContentType.xlsx,
				});
				fileSaver.saveAs(blob, `Report.${MimeTypes.Extensions.xlsx}`);
			}
		} catch (error: any) {
			notificationService.showErrorMessageWithError(error.toString(), undefined, error);
		} finally {
			this.setState({ loading: false });
		}
	};

	public getRefreshRateItems = () => {
		return (
			// Show/Hide panel with this.op.toggle(e: MouseEvent)
			// <OverlayPanel ref={(el) => this.op = el}>
			//     {/* Items here... */}
			// </OverlayPanel>

			// <div className="dropdown-content" onClick={this.handleRefreshRate}>
			//     <a>30</a>
			//     <a>60</a>
			//     <a>90</a>
			// </div>
			<div />
		);
	};

	public handleRefreshRate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (e.target instanceof HTMLAnchorElement) {
			const clickedRefreshRate = +e.target.textContent!; // small performance boost over innerText;

			if (clickedRefreshRate in availableRefreshRates) {
				this.props.setRefreshRate(
					this.props.idOfRefreshRateOnRedux,
					clickedRefreshRate as availableRefreshRatesKeys
				);
			}
		}
	};

	public formatRefreshRate(rate: availableRefreshRatesKeys) {
		//TODO: use moment to format it
		const tempRate = typeof rate === 'number' ? rate : 0;

		return `${tempRate} ${localized('Unit_Seconds')}`;
	}

	public render() {
		return (
			<div className="flex-direction-row">
				<Button className={buttonClassName} label={localized('EditColumns')} onClick={this.handleEditColumn} />
				{this.props.isAdminOrHigher && (
					<Button
						className={buttonClassName}
						label={localized('MoveMachine')}
						onClick={this.handleMoveMachine}
					/>
				)}

				<BiTooltip overlay={localized('ExportToExcel')}>
					<BiButton
						onClick={this.handleExcelFile}
						disabled={this.state.loading}
						colorTheme="org-primary-grey"
						containerTheme="normal-button-corners"
					>
						{this.state.loading ? (
							<Spinner
								shouldOverlay={false}
								shouldUseAbsolutePositioning={false}
								spinnerSize="spinner-container-1-4th-size"
								useMarginsForCorrectingCentering={false}
							/>
						) : (
							<img className="export-icon" src={images.excel} alt={localized('ExcelFile')} />
						)}
					</BiButton>
				</BiTooltip>
				{this.state.editColumnsDialogVisible && (
					<EditColumn
						header={localized('ChooseColumns')}
						visible={true}
						onHide={this.handleHideEditColumnDialog}
						showHeader={false}
					/>
				)}
				{this.state.moveMachinesDialogVisible && (
					<MoveMachine
						header={localized('ChooseColumns')}
						visible={true}
						onHide={this.handleHideMoveMachine}
						showHeader={false}
					/>
				)}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TableUtility);
