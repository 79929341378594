import { DialogProps } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { i18nTranslationKey, localized } from 'state/i18n';
import BiTextArea from 'view/components/bi-text/bi-textarea';
import BiButton from 'view/shared/components/buttons/bi-button/bi-button';
import BiTextDialog from 'view/shared/components/dialogs/bi-text-dialog/bi-text-dialog';

export type AddDeviceDto = {
	id: string;
	message: string;
};

type deviceType = 'weight' | 't-tracker' | 'x-tracker' | 'teltonika-indicator';

type Props = DialogProps & {
	onConfirm: (addDeviceDto: AddDeviceDto) => Promise<void>;
	onDelete: () => void;
	currentId?: string;
	currentMessage?: string;
	isEdit?: boolean;
	deviceType: deviceType;
};

interface State {
	newUnitId: string;
	isEdit: boolean | undefined;
	isConfirmDeleteVisible: boolean;
	message: string;
}

export class AddDevice extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			newUnitId: props.currentId ?? '',
			message: props.currentMessage ?? '',
			isEdit: props.isEdit,
			isConfirmDeleteVisible: false,
		};
	}
	componentDidUpdate(prevProps: Props, _prevState: State) {
		if (prevProps.currentId !== this.props.currentId && this.props.currentId) {
			this.setState({
				newUnitId: this.props.currentId,
			});
		}
		if (prevProps.currentMessage !== this.props.currentMessage && this.props.currentMessage) {
			this.setState({
				message: this.props.currentMessage,
			});
		}
	}

	getTranslationKeys = (deviceType: string): i18nTranslationKey[] => {
		switch (deviceType) {
			case 'weight':
				return ['ConfirmWeightUnitDeletion', 'DeleteWeightUnitDialog'];
			case 'teltonika-indicator':
				return ['ConfirmTeltonikaIndicatorDeletion', 'DeleteTeltonikaIndicatorDialog'];
			default:
				return ['ConfirmTrackerUnitDeletion', 'DeleteTrackerUnitDialog'];
		}
	};

	public render() {
		const [confirmDeleteTitle, confirmDeleteBody]: i18nTranslationKey[] = this.getTranslationKeys(
			this.props.deviceType
		);

		return (
			<div>
				<BiTextDialog
					{...this.props}
					title={this.props.header}
					subtitle={this.props.deviceType === 'x-tracker' ? localized('XtelCustomMessageSubtitle') : ''}
				>
					<div className="p-grid flex-center-column">
						<div className="p-col-2 text-bold align-self-center">
							{this.props.deviceType !== 'teltonika-indicator' ? localized('unitId') : localized('IMEI')}
						</div>
						<div className="p-col-10">
							<InputText
								placeholder={
									this.props.deviceType !== 'teltonika-indicator'
										? localized('EnterANewUnitId')
										: localized('EnterIMEI')
								}
								type={this.props.deviceType !== 't-tracker' ? 'text' : 'number'}
								value={this.state.newUnitId}
								onChange={this.setNewUnitId}
							/>
						</div>
					</div>
					{this.props.deviceType === 'x-tracker' && (
						<div className="p-grid flex-center-column">
							<div className="p-col-2 text-bold">{'Message when triggered'}</div>
							<div className="p-col-10">
								<BiTextArea
									placeholder={localized('InsertCustomMessage')}
									onChange={this.setMessage}
									value={this.state.message}
								/>
							</div>
						</div>
					)}
					<br />
					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={this.handleCancel}
							colorTheme="org-primary-grey"
							containerClassName="margin-right-24px"
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Cancel')}
						</BiButton>
						{!this.props.currentId ? (
							<BiButton
								onClick={this.handleConfirmEdits}
								colorTheme="org-green"
								containerTheme="slim-with-rounded-corners"
								disabled={!this.state.newUnitId}
							>
								{localized('Save')}
							</BiButton>
						) : (
							<>
								<BiButton
									onClick={this.handleConfirmEdits}
									colorTheme="org-green"
									containerTheme="slim-with-rounded-corners"
								>
									{localized('Update')}
								</BiButton>
								<BiButton
									containerClassName="margin-left-20px"
									onClick={this.handleDeleteButtonPressed}
									colorTheme="org-red"
									containerTheme="slim-with-rounded-corners"
								>
									{localized('Delete')}
								</BiButton>
							</>
						)}
					</div>
				</BiTextDialog>

				<BiTextDialog
					onHide={this.handleHideConfirmDelete}
					visible={this.state.isConfirmDeleteVisible}
					title={localized(confirmDeleteTitle)}
				>
					<span>{localized(confirmDeleteBody)}</span>
					<div className="flex-end-row margin-top-10px">
						<BiButton
							onClick={this.handleHideConfirmDelete}
							colorTheme="org-primary-grey"
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Cancel')}
						</BiButton>
						<BiButton
							containerClassName="margin-left-20px"
							onClick={this.handleConfirmDelete}
							colorTheme="org-red"
							containerTheme="slim-with-rounded-corners"
						>
							{localized('Delete')}
						</BiButton>
					</div>
				</BiTextDialog>
			</div>
		);
	}

	private handleCancel = () => this.props.onHide();

	private setNewUnitId = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newUnitId: e.target.value });
	};

	private setMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ message: e.currentTarget.value });
	};

	private handleDeleteButtonPressed = () => {
		this.setState({ ...this.state, isConfirmDeleteVisible: true });
	};

	private handleConfirmDelete = () => {
		this.setState({ ...this.state, isConfirmDeleteVisible: false });
		this.props.onDelete();
	};

	private handleConfirmEdits = () => {
		this.props.onConfirm({ id: this.state.newUnitId, message: this.state.message });
		this.setState({ ...this.state, isEdit: true });
	};

	private handleHideConfirmDelete = () => this.setState({ ...this.state, isConfirmDeleteVisible: false });
}
