import { IBaleStorageDto, IUnitDetailsDto } from 'api/api';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpsertBaleStorage } from 'state/ducks/machine-details/operations';
import { localized } from 'state/i18n';
import { images } from 'utilities/images';
import { IdDataTuple } from 'utilities/types-util';
import BiRadiobutton from 'view/shared/components/buttons/bi-radiobutton/bi-radiobutton';
import { ConfirmDialog } from '../../../../../shared/components/dialogs/bi-dialog-confirm/dialog-confirm';
import BaleSettingCard from './bale-setting-card';
import './bale-settings.scss';
import { baleStorageBuilder } from './bale-storage-helper';

type Props = {
	currMachine: IdDataTuple<IUnitDetailsDto, string>;
};
const AutomaticBaleCounterComponent: FC<Props> = React.memo((props: Props) => {
	const dispatch = useDispatch();

	const [isDialogVisible, setDialogVisible] = useState(false);
	const [isAutomaticReset, setIsAutomaticReset] = useState<boolean | undefined>(
		props.currMachine.data.automaticBaleCountReset
	);

	const showDialog = () => {
		setDialogVisible(true);
	};

	const hideDialog = () => {
		setDialogVisible(false);
	};

	const onConfirm = async () => {
		const baleStorage: IBaleStorageDto = baleStorageBuilder(props.currMachine.data, {
			automaticReset: isAutomaticReset,
		});

		await UpsertBaleStorage(baleStorage, !props.currMachine.data.baleStorageId)(dispatch);
		hideDialog();
	};

	const onClickAuto = () => {
		setIsAutomaticReset(true);
	};

	const onClickManual = () => {
		setIsAutomaticReset(false);
	};

	return (
		<>
			<BaleSettingCard
				title={localized('AutomaticBalesOnSiteReset')}
				description1={localized('ChooseBaleCounterRegistration')}
				icon={images.automaticBaleCounter}
				tooltip={[
					`1=${localized('BaleCounterAutomaticDescription')}`,
					`2=${localized('BaleCounterManualDescription')}`,
				]}
				editButton={{
					text: localized('CounterRegistration'),
					value: props.currMachine.data.automaticBaleCountReset
						? 1
						: props.currMachine.data.automaticBaleCountReset === false
						? 2
						: undefined,
					onClick: showDialog,
				}}
			/>

			<ConfirmDialog
				onHide={hideDialog}
				onCancel={hideDialog}
				onConfirm={onConfirm}
				header={localized('AutomaticBalesOnSiteReset')}
				dialogMessage={localized('ChooseBaleCounterRegistration')}
				confirmColor={'org-primary'}
				visible={isDialogVisible}
			>
				<div className="flex-direction-column">
					<BiRadiobutton
						checked={isAutomaticReset}
						onClick={onClickAuto}
						colorTheme="org-primary-colors-only"
						containerClassName="margin-bottom-20px"
					>
						{localized('BaleCounterAutomaticDescription')}
					</BiRadiobutton>

					<BiRadiobutton
						checked={isAutomaticReset === false}
						onClick={onClickManual}
						colorTheme="org-primary-colors-only"
						containerClassName="margin-bottom-20px"
					>
						{localized('BaleCounterManualDescription')}
					</BiRadiobutton>
				</div>
			</ConfirmDialog>
		</>
	);
});

export default AutomaticBaleCounterComponent;
