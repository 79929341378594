import { TeltonikaIndicatorClient } from 'api/api';
import { AsyncOperationBuilder } from 'utilities/api-helper';
import { teltonikaIndicatorClient } from '../api-clients';
import {
	CreateTeltonikaIndicatorAction,
	DeleteTeltonikaIndicatorAction,
	GetTeltonikaIndicatorAction,
	UpdateTeltonikaIndicatorAction,
} from './actions';

export function createTeltonikaIndicator(teltonikaImei: string, unitId: number) {
	return AsyncOperationBuilder(
		CreateTeltonikaIndicatorAction,
		apiClient => (apiClient as TeltonikaIndicatorClient).createTeltonikaIndicator(teltonikaImei, unitId),
		{ teltonikaImei, unitId },
		teltonikaIndicatorClient
	);
}

export function getTeltonikaIndicator(serialNumber: string) {
	return AsyncOperationBuilder(
		GetTeltonikaIndicatorAction,
		apiClient => (apiClient as TeltonikaIndicatorClient).getTeltonikaIndicator(serialNumber),
		{ serialNumber },
		teltonikaIndicatorClient
	);
}

export function updateTeltonikaIndicator(teltonikaImei: string, unitId: number) {
	return AsyncOperationBuilder(
		UpdateTeltonikaIndicatorAction,
		apiClient => (apiClient as TeltonikaIndicatorClient).updateTeltonikaIndicator(teltonikaImei, unitId),
		{ teltonikaImei, unitId },
		teltonikaIndicatorClient
	);
}

export function deleteTeltonikaIndicator(unitId: number) {
	return AsyncOperationBuilder(
		DeleteTeltonikaIndicatorAction,
		apiClient => (apiClient as TeltonikaIndicatorClient).deleteTeltonikaIndicator(unitId),
		{ unitId },
		teltonikaIndicatorClient
	);
}
