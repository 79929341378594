import {
	AdministrationClient,
	BaleStorageClient,
	ContainerClient,
	DashboardClient,
	DebtorClient,
	DocumentClient,
	FavoriteDevicesClient,
	GPSHistoryClient,
	GroupsClient,
	IoTHubDuplicateDecommissionClient,
	UnitExcelClient,
	NotificationCustomMessageClient,
	NotificationDefinitionClient,
	NotificationEmailClient,
	NotificationHistoryClient,
	NotificationTypeClient,
	NotificationUnsubscribeClient,
	PartnerAreaClient,
	ProfileClient,
	ReleaseNoteClient,
	ReportsExcelClient,
	ServiceToolClient,
	ServiceToolCodeClient,
	ServiceVideoClient,
	TableSettingsClient,
	TeltonikaIndicatorClient,
	UnitClient,
	UnitDetailsClient,
	UnitLocationClient,
	UnitManufacturerClient,
	UnitModelClient,
	UnitModelCodeClient,
	UnitReportsClient,
	UnitSelectedClient,
	UnitStateHistoryClient,
	UnitStateHistoryExcelClient,
	UnitTrackerClient,
	UnitTypeClient,
	UnitWeightClient,
	UserGroupPermissionsClient,
	UserSettingsClient,
	XtelUnitTrackerClient,
} from 'api/api';

import { ApiBaseUrl } from 'utilities/constants';

export const unitDetailsClient = new UnitDetailsClient(ApiBaseUrl);
export const tableSettingsClient = new TableSettingsClient(ApiBaseUrl);
export const unitExcelClient = new UnitExcelClient(ApiBaseUrl);
export const unitLocationClient = new UnitLocationClient(ApiBaseUrl);
export const unitReportsClient = new UnitReportsClient(ApiBaseUrl);
export const userGroupPermissionsClient = new UserGroupPermissionsClient(ApiBaseUrl);
export const reportsExcelClient = new ReportsExcelClient(ApiBaseUrl);
export const unitStateHistoryExcelClient = new UnitStateHistoryExcelClient(ApiBaseUrl);
export const unitSelectedClient = new UnitSelectedClient(ApiBaseUrl);
export const userSettingsClient = new UserSettingsClient(ApiBaseUrl);
export const groupsClient = new GroupsClient(ApiBaseUrl);
export const dashboardClient = new DashboardClient(ApiBaseUrl);
export const notificationDefinitionClient = new NotificationDefinitionClient(ApiBaseUrl);
export const notificationTypeClient = new NotificationTypeClient(ApiBaseUrl);
export const notificationHistoryClient = new NotificationHistoryClient(ApiBaseUrl);
export const notificationCustomMessageClient = new NotificationCustomMessageClient(ApiBaseUrl);
export const adminstrationClient = new AdministrationClient(ApiBaseUrl);
export const releaseNoteClient = new ReleaseNoteClient(ApiBaseUrl);
export const documentsClient = new DocumentClient(ApiBaseUrl);
export const notificationEmailClient = new NotificationEmailClient(ApiBaseUrl);
export const gpsHistoryClient = new GPSHistoryClient(ApiBaseUrl);
export const containerClient = new ContainerClient(ApiBaseUrl);
export const unitTrackerClient = new UnitTrackerClient(ApiBaseUrl);
export const baleStorageClient = new BaleStorageClient(ApiBaseUrl);
export const serviceVideoClient = new ServiceVideoClient(ApiBaseUrl);
export const unitClient = new UnitClient(ApiBaseUrl);
export const unitStateHistoryClient = new UnitStateHistoryClient(ApiBaseUrl);
export const serviceToolCodeClient = new ServiceToolCodeClient(ApiBaseUrl);
export const debtorClient = new DebtorClient(ApiBaseUrl);
export const xtelUnitTrackerClient = new XtelUnitTrackerClient(ApiBaseUrl);
export const unitWeightClient = new UnitWeightClient(ApiBaseUrl);
export const serviceToolClient = new ServiceToolClient(ApiBaseUrl);
export const profileClient = new ProfileClient(ApiBaseUrl);
export const partnerAreaClient = new PartnerAreaClient(ApiBaseUrl);
export const notificationUnsubscribeClient = new NotificationUnsubscribeClient(ApiBaseUrl);
export const ioTHubDuplicateDecommissionClient = new IoTHubDuplicateDecommissionClient(ApiBaseUrl);
export const favoriteDevicesClient = new FavoriteDevicesClient(ApiBaseUrl);
export const unitModelClient = new UnitModelClient(ApiBaseUrl);
export const unitManufacturerClient = new UnitManufacturerClient(ApiBaseUrl);
export const unitTypeClient = new UnitTypeClient(ApiBaseUrl);
export const unitModelCodeClient = new UnitModelCodeClient(ApiBaseUrl);
export const teltonikaIndicatorClient = new TeltonikaIndicatorClient(ApiBaseUrl);

export type ApiClient =
	| UnitDetailsClient
	| TableSettingsClient
	| UnitStateHistoryClient
	| UnitExcelClient
	| UserGroupPermissionsClient
	| UnitReportsClient
	| ReportsExcelClient
	| GroupsClient
	| DashboardClient
	| UserSettingsClient
	| NotificationDefinitionClient
	| NotificationTypeClient
	| NotificationHistoryClient
	| NotificationCustomMessageClient
	| AdministrationClient
	| ReleaseNoteClient
	| DocumentClient
	| NotificationEmailClient
	| GPSHistoryClient
	| ContainerClient
	| UnitTrackerClient
	| BaleStorageClient
	| ServiceVideoClient
	| UnitClient
	| UnitLocationClient
	| UnitSelectedClient
	| ServiceToolCodeClient
	| DebtorClient
	| XtelUnitTrackerClient
	| UnitWeightClient
	| ServiceToolClient
	| ProfileClient
	| PartnerAreaClient
	| NotificationUnsubscribeClient
	| IoTHubDuplicateDecommissionClient
	| FavoriteDevicesClient
	| UnitModelClient
	| UnitManufacturerClient
	| UnitModelCodeClient
	| UnitTypeClient
	| TeltonikaIndicatorClient;
