import {
	DataTypeProvider,
	DataTypeProviderProps,
	IntegratedSorting,
	Sorting,
	SortingState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	DragDropProvider,
	Grid,
	Table,
	TableColumnReordering,
	TableColumnResizing,
	TableHeaderRow,
	VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import { IUnitStateHistoryDto, TableId } from 'api/api';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setOrder, setSize, setSorting } from 'state/ducks/history-table-settings/operations';
import {
	SortingGeneric,
	TableColumnWidthInfoGeneric,
	UnitHistoryDtoKeys,
} from 'state/ducks/history-table-settings/types';
import { localizedDynamic } from 'state/i18n';
import { AppState } from 'state/store';
import { Compactor } from 'utilities/constants';
import { getFillLevel } from 'utilities/machine-util';
import { biConvertNumberToSeparatedString } from 'utilities/number-helpers';
import { getFormattedConnectionStatusAsSignalStrength } from 'utilities/table-utilities';
import { FormatWeightWithUnit } from 'utilities/unit-utils';
import { DateFormatterModes, getDateToString } from 'view/shared/components/date-to-string';
import ProgressBar from 'view/shared/components/progress-bar/progress-bar';
import BiNoData from './bi-no-data/bi-no-data';
import { ROW_HEIGHT } from './bi-table';
import { unitHistorySorter } from './history-table-data-sorting';
import HistoryTableEdit from './history-table-edit';
import './history-table.scss';

interface UnitHistoryDtoForTable extends IUnitStateHistoryDto {
	index: number;
	// NOTE: Not a mistake. When sorting by poperties the column value will be used by the grid.
	// 'updatedDateTuple' has no value and 'updatedDate' dosen't contain enough information to sort correctly.
	// This way, we have all the nessesary data available to use the shared sorting function.
	updatedDateTuple: IUnitStateHistoryDto;
}

const mapStateToProps = (state: AppState, ownProps: AppProps) => {
	const hasWeight = ownProps.data?.some(d => d.weightLastOutput) === true;

	let columns = state.historyTableSettingsReducer.columns;

	if (!hasWeight) {
		columns = columns.filter(c => c.name !== 'weightLastOutput');
	}

	if (ownProps.isBaler) {
		columns = columns.filter(c => c.name !== 'fillLevel');
	} else {
		columns = columns.filter(c => c.name !== 'balesOnSite');
	}

	return {
		sorting: state.historyTableSettingsReducer.sorting,
		columnOrder: state.historyTableSettingsReducer.columnOrder,
		columnWidths: state.historyTableSettingsReducer.columnWidths,
		columns: columns,
		userSetting: state.userSettingReducer.userSettings,
	};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSorting: (sorting: SortingGeneric<UnitHistoryDtoKeys>[]) => setSorting(sorting)(dispatch),
	setSize: (size: TableColumnWidthInfoGeneric<UnitHistoryDtoKeys>[]) => setSize(size)(dispatch),
	setOrder: (order: UnitHistoryDtoKeys[]) => setOrder(order)(dispatch),
});

interface AppProps {
	isLoading?: boolean;
	data?: IUnitStateHistoryDto[];
	machineId: string;
	isBaler: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppProps;

interface State {
	dateColumns: UnitHistoryDtoKeys[];
	fillLevelColumns: UnitHistoryDtoKeys[];
	failureMessageColumns: UnitHistoryDtoKeys[];
	connectionStatusAsSignalStrength: UnitHistoryDtoKeys[];
	userDefinedComments: UnitHistoryDtoKeys[];
	userDefinedWeight: UnitHistoryDtoKeys[];
	weightLastOutput: UnitHistoryDtoKeys[];
	editHistoryBtn: UnitHistoryDtoKeys[];
	balesReadyForPickupColumns: UnitHistoryDtoKeys[];
	fullCounter: UnitHistoryDtoKeys[];
	totalRunningHours: UnitHistoryDtoKeys[];
	totalNumberOfCycles: UnitHistoryDtoKeys[];
	pressSeries: UnitHistoryDtoKeys[];
	pressSeriesSinceLastOutput: UnitHistoryDtoKeys[];
	cyclesSinceLastOutput: UnitHistoryDtoKeys[];
}

const getRowId = (row: IUnitStateHistoryDto) => {
	return row.id;
};

const TableRow = (obj: any) => <VirtualTable.Row {...obj} />;

const TableComponent = ({ ...restProps }) => <VirtualTable.Table {...restProps} className="bi-report-table-striped" />;

const HeaderComponent = ({ ...restProps }) => <VirtualTable.TableHead {...restProps} className="table-header" />;

const GenericTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider {...props} />;

const numberFormatter = (obj: { value: number }) => <span>{biConvertNumberToSeparatedString(obj.value)}</span>;

const ConnectionStatusAsSignalStrengthFormatter = (obj: DataTypeProvider.ValueFormatterProps) =>
	getFormattedConnectionStatusAsSignalStrength(obj.row as IUnitStateHistoryDto);

const dateFormatter = (obj: DataTypeProvider.ValueFormatterProps) => {
	return (
		<span>{getDateToString((obj.row as IUnitStateHistoryDto).updatedDate, DateFormatterModes.withMinutes)}</span>
	);
};

const fillLevelFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const currMachine = obj.row as IUnitStateHistoryDto;

	const fillValues = getFillLevel(Compactor, currMachine.fillLevel, undefined);

	return <ProgressBar value={fillValues.barValue} maxValue={fillValues.maxValue} />;
};

const balesReadyForPickupFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const currMachine = obj.row as IUnitStateHistoryDto;

	if (currMachine.balesOnSite == null || currMachine.baleStorageSize == null) {
		return <div className={'textAlignCenter'}>-</div>;
	}

	return <ProgressBar value={currMachine.balesOnSite} maxValue={currMachine.baleStorageSize} />;
};

const failureMessageFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
	const machine = obj.row as IUnitStateHistoryDto;
	let errorMessage: string = '';

	if (machine.errors && machine.errors.length > 0) {
		errorMessage = machine.errors.map(er => localizedDynamic(`ErrorIds.${er.readableError}`)).join(', ');
	}

	return <span>{errorMessage}</span>;
};

const rootStyle: React.CSSProperties = { height: '100%' };
const Root = (props: Grid.RootProps) => (
	<Grid.Root className="devextreme-react-grid-remove-margin-bottom" {...props} style={rootStyle} />
);

const integratedSortingColumnExtensions: Array<IntegratedSorting.ColumnExtension> = [
	{
		columnName: 'updatedDateTuple',
		compare: unitHistorySorter,
	},
];

class HistoryTable extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			dateColumns: ['updatedDateTuple'],
			fillLevelColumns: ['fillLevel'],
			failureMessageColumns: ['errors'],
			connectionStatusAsSignalStrength: ['connectionStatusAsSignalStrength'],
			userDefinedComments: ['userDefinedComment'],
			userDefinedWeight: ['userDefinedWeight'],
			weightLastOutput: ['weightLastOutput'],
			editHistoryBtn: ['editHistoryDto'],
			balesReadyForPickupColumns: ['balesOnSite'],
			fullCounter: ['unitsEmptied'],
			totalRunningHours: ['totalRunningHours'],
			totalNumberOfCycles: ['totalNumberOfCycles'],
			pressSeries: ['pressSeries'],
			pressSeriesSinceLastOutput: ['pressSeriesSinceLastOutput'],
			cyclesSinceLastOutput: ['cyclesSinceLastOutput'],
		};

		this.changeColumnOrder = this.changeColumnOrder.bind(this);
		this.changeColumnWidths = this.changeColumnWidths.bind(this);
		this.changeSorting = this.changeSorting.bind(this);
	}

	editHistoryDtoFormatter = (obj: DataTypeProvider.ValueFormatterProps): JSX.Element => {
		const currMachine = obj.row as IUnitStateHistoryDto;
		return <HistoryTableEdit row={currMachine} {...this.props} />;
	};

	public render() {
		let data: UnitHistoryDtoForTable[] = [];
		if (this.props.data) {
			data = this.props.data.map((d, index) => {
				return {
					...d,
					index,
					updatedDateTuple: d,
				};
			});
		}
		let columnsCopy = [...this.props.columns];

		return (
			<Grid rows={data} columns={columnsCopy} getRowId={getRowId} rootComponent={Root}>
				<GenericTypeProvider
					for={this.state.connectionStatusAsSignalStrength}
					formatterComponent={ConnectionStatusAsSignalStrengthFormatter}
				/>
				<GenericTypeProvider for={this.state.dateColumns} formatterComponent={dateFormatter} />
				<GenericTypeProvider for={this.state.fillLevelColumns} formatterComponent={fillLevelFormatter} />
				<GenericTypeProvider
					for={this.state.balesReadyForPickupColumns}
					formatterComponent={balesReadyForPickupFormatter}
				/>
				<GenericTypeProvider
					for={this.state.failureMessageColumns}
					formatterComponent={failureMessageFormatter}
				/>
				<GenericTypeProvider
					for={this.state.editHistoryBtn}
					formatterComponent={this.editHistoryDtoFormatter}
				/>
				<GenericTypeProvider
					for={this.state.userDefinedWeight}
					formatterComponent={this.userDefinedWeightFormatter}
				/>

				<GenericTypeProvider
					for={this.state.weightLastOutput}
					formatterComponent={this.userDefinedWeightFormatter}
				/>

				<GenericTypeProvider for={this.state.fullCounter} formatterComponent={numberFormatter} />
				<GenericTypeProvider for={this.state.totalRunningHours} formatterComponent={numberFormatter} />
				<GenericTypeProvider for={this.state.totalNumberOfCycles} formatterComponent={numberFormatter} />
				<GenericTypeProvider for={this.state.cyclesSinceLastOutput} formatterComponent={numberFormatter} />
				<GenericTypeProvider for={this.state.pressSeries} formatterComponent={numberFormatter} />
				<GenericTypeProvider for={this.state.pressSeriesSinceLastOutput} formatterComponent={numberFormatter} />

				<DragDropProvider />
				<SortingState sorting={this.props.sorting} onSortingChange={this.changeSorting} />
				<IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
				<VirtualTable
					tableComponent={TableComponent}
					headComponent={HeaderComponent}
					height="auto"
					noDataCellComponent={this.noCell}
					rowComponent={TableRow}
					estimatedRowHeight={ROW_HEIGHT}
				/>
				<TableColumnReordering onOrderChange={this.changeColumnOrder} order={this.props.columnOrder} />
				<TableColumnResizing
					onColumnWidthsChange={this.changeColumnWidths}
					columnWidths={this.props.columnWidths}
				/>
				<TableHeaderRow showSortingControls={true} />
			</Grid>
		);
	}
	private noCell = (rest: Table.NoDataCellProps) => this.noDataCellComponent(rest, this.props.isLoading);
	private noDataCellComponent = (restProps: Table.NoDataCellProps, loading = this.props.isLoading): JSX.Element => (
		<BiNoData isLoading={this.props.isLoading} noDataCellProps={restProps} tableId={TableId.Histories} />
	);

	private changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
		this.props.setSize(columnWidths as TableColumnWidthInfoGeneric<UnitHistoryDtoKeys>[]);
	};

	private changeColumnOrder = (newOrder: string[]) => {
		this.props.setOrder(newOrder as UnitHistoryDtoKeys[]);
	};

	private changeSorting = (sorting: Sorting[]) => {
		this.props.setSorting(sorting as SortingGeneric<UnitHistoryDtoKeys>[]);
	};

	private userDefinedWeightFormatter = (obj: { value: number | undefined }) => {
		return <span>{FormatWeightWithUnit(obj?.value, this.props.userSetting.profileUnits)}</span>;
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable);
